import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ns-input-headers-headlines',
  templateUrl: './input-headers-headlines.component.html',
  styleUrls: ['./input-headers-headlines.component.scss']
})
export class InputHeadersHeadlinesComponent {
    @Input() label: string;
    @Input() value: string;
    @Input() placeholder: string;
    @Input() maxLength: number;
    @Output() valueChange = new EventEmitter<string>();
    @Output() focus = new EventEmitter<void>();

    titleBorderClass: string;
    titleTextClass: string;
    noticeText: String;
    placeholderText: String;
    errorMsg:string[]=[ "Approaching text character limit",
        "Text character limit reached",
        "Text character limit exceeded",
        "Insight Headline is a required field",
        "*Enter headline text"];
    noticeVisible = false;

    constructor() {
    }

    ngOnInit(): void {
        this.value = this.value == ""? undefined:this.value;
        this.validateInputValue();
        this.placeholderText = this.placeholder;
    }

    onValueChange($event: KeyboardEvent) {
        this.validateInputValue();
        this.valueChange.emit(this.value);
        if($event.code == "KeyC"){
            $event.stopPropagation();
        }
    }

    onFocus() {
        this.focus.emit();
    }

    validateInputValue() {
        const valueLength = this.value?.length;
        switch (true) {
            case (valueLength == 0):
                this.titleBorderClass = 'border-error';
                this.titleTextClass = 'text-red-color';
                this.noticeText = this.errorMsg[3];
                this.placeholderText = this.errorMsg[4];
                this.noticeVisible = true;
                break;
            case (valueLength > this.maxLength):
                this.titleBorderClass = 'border-error';
                this.titleTextClass = 'text-red-color';
                this.noticeText = this.errorMsg[2];
                this.noticeVisible = true;
                break;
            case (0 < valueLength && valueLength <= this.maxLength-10):
                this.titleBorderClass = 'border-active';
                this.titleTextClass = 'text-black-color';
                this.noticeText = '';
                break;
            case (this.maxLength-10 < valueLength && valueLength < this.maxLength):
                this.titleBorderClass = 'border-alert';
                this.titleTextClass = 'text-yellow-color';
                this.noticeText = this.errorMsg[0];
                break;
            case (valueLength == this.maxLength):
                this.titleBorderClass = 'border-alert';
                this.titleTextClass = 'text-yellow-color';
                this.noticeText = this.errorMsg[1];
                break;
            default:
                this.titleBorderClass = '';
                this.titleTextClass = '';
        }
    }

    focusIn(){
        this.noticeVisible = true;
    }

    focusout(){
        this.noticeVisible = (this.value?.length == 0 || this.value?.length > 80);
    }
}
