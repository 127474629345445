import {TranslateService} from '@ngx-translate/core';
import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {ReportService} from '@platform/services/report.service';
import {ToasterService} from '@platform/services/toaster.service';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {Deliverable} from '@platform/models/deliverable.model';
import {Subscription} from 'rxjs';
import {DeliverableConfigurationUtilService} from "@platform/services/deliverable-configuration-util.service";
import {ConfirmationDialogComponent} from "@app/common/confirmation-dialog/confirmation-dialog.component";
import {ConfirmationDialogService} from "@app/common/confirmation-dialog/confirmation-dialog.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {Report} from '@platform/models/report.model';
import {UserInfo} from '@platform/models/user-info.model';
import {DeliverableConfiguration} from '@platform/models/deliverable-configuration.model';

@Component({
    selector: 'ns-deliverable-configuration',
    templateUrl: './deliverable-configuration.component.html',
    styleUrls: ['./deliverable-configuration.component.scss'],
})
export class DeliverableConfigurationComponent implements OnInit, OnChanges {

    @Input() deliverablesInReport: Deliverable[];
    @Input() deliverableConfigurationsInReport: DeliverableConfiguration[];
    @Input() defaultCardsViewExist: boolean;
    @Input() report: Report;
    @Output() onFlyoutMenuClick = new EventEmitter<any>();
    @Input() userInfo: UserInfo;
    @Output() changeOfShoeHideIndicator = new EventEmitter<any>();
    @Output() closeFlyoutMenuEvent = new EventEmitter();
    @Output() moveToPreviousMenuEvent = new EventEmitter();
    @Output() discardChangesInMainMenu = new EventEmitter();

    selectedMenu = '';
    completedDeliverables = ['forecast.volume.revenue', 'forecast.marketing.plan.summary', 'forecast.variety.split', 'purchase.preference'];
    childMenu = ['forecast.volume.revenue', 'forecast.marketing.plan.summary', 'forecast.variety.split', 'purchase.preference'];
    deliverablesReadyForConfiguration = [DeliverableType.CORRELATIONS.type, DeliverableType.SURVEY_QUESTION.type, DeliverableType.FINANCIAL_POTENTIAL.type, DeliverableType.FACTORS.type];
    /**
     * Subscription for deliverableInfo
     *
     * @type {Subscription}
     * @memberof DeliverablesComponent
     */
    subscriptions: Array<Subscription>;

    constructor(
        private reportService: ReportService,
        private toasterService: ToasterService,
        private translate: TranslateService,
        private deliverableInfoService: DeliverableInfoService,
        private deliverableConfigurationUtilService: DeliverableConfigurationUtilService,
        private confirmationDialogService: ConfirmationDialogService,
        private dialog: MatDialog,
    ) {
        this.subscriptions = [];
    }

    /**
     * Initialize the ReportSettings component view.
     * @memberof DeliverableConfigurationComponent
     */
    ngOnInit(): void {
        this.deliverablesInReport = this.sortDeliverables(this.deliverablesInReport);
    }

    ngOnChanges(changes: SimpleChanges) {
    }


    getLabel(type): String {
        const deliverableInfo = this.deliverableInfoService.getDeliverableTypeByTypeName(type);
        return this.translate.instant(deliverableInfo.labelKey);
    }

    onChangeOfShowHideIndicator() {
        this.changeOfShoeHideIndicator.emit();
    }

    sortDeliverables(array) {
        const deliverables = array.filter(it => it && ((it.type && !it.type.startsWith('forecast'))));
        const forecastDeliverables = array.filter(it => it && (it.type && it.type.startsWith('forecast')));
        const sortFunction = (a, b) => {
            return a.position - b.position;
        };
        const sortedDeliverables = [...deliverables].sort(sortFunction);
        const sortedForecastingDeliverables = [...forecastDeliverables].sort(sortFunction);
        array = [...sortedDeliverables, ...sortedForecastingDeliverables];
        return array;
    }

    onClick(deliverableinfo): void {
        if (this.completedDeliverables.includes(deliverableinfo) || (this.deliverablesReadyForConfiguration.includes(deliverableinfo) && this.isDeliverableConfigurationFlagEnabled())) {
            if (this.isFormDirty()) {
                const dialogConfig = this.confirmationDialogService.getContinueWithoutSavingConfig();
                const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(value => {
                    if (value === 'CANCEL') {
                        this.dialog.closeAll();
                    } else {
                        this.selectedMenu = deliverableinfo;
                        this.discardChangesInMainMenu.emit();
                        this.onFlyoutMenuClick.emit(deliverableinfo);
                    }
                });
            } else {
                this.selectedMenu = deliverableinfo;
                this.onFlyoutMenuClick.emit(deliverableinfo);
            }
        }
    }

    isChildMenuAvailable(deliverableinfo) {
        return this.childMenu.includes(deliverableinfo) ||
            (this.deliverablesReadyForConfiguration.indexOf(deliverableinfo) !== -1 && this.isDeliverableConfigurationFlagEnabled());
    }

    onCloseButtonClick(): void {
        if (this.isFormDirty()) {
            const dialogConfig = this.confirmationDialogService.getContinueWithoutSavingConfig();
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(value => {
                if (value === 'CANCEL') {
                    this.dialog.closeAll();
                } else {
                    this.closeFlyoutMenuEvent.emit();
                }
            });
        } else {
            this.closeFlyoutMenuEvent.emit();
        }
    }

    returnBack(): void {
        this.moveToPreviousMenuEvent.emit();
    }

    isFormDirty(): boolean {
        let initialDeliverablesInReport = [];
        if (this.isDeliverableConfigurationFlagEnabled()) {
            initialDeliverablesInReport = this.report.deliverables.filter(d => d.isComplete || d.type === 'correlations').map(u => Object.assign({}, u, {showDeliverable: (u.showDeliverable !== null && u.showDeliverable !== undefined && u.showDeliverable + '' === 'false' || u.type === 'correlations') ? u.showDeliverable : true}));
        } else {
            initialDeliverablesInReport = this.report.deliverables.filter(d => d.isComplete).map(u => Object.assign({}, u, {showDeliverable: u.showDeliverable !== null && u.showDeliverable !== undefined && u.showDeliverable + '' === 'false' ? u.showDeliverable : true}));
        }
        initialDeliverablesInReport = this.sortDeliverables(initialDeliverablesInReport);
        this.deliverablesInReport = this.sortDeliverables(this.deliverablesInReport);
        return this.deliverableConfigurationUtilService.isDeliverablesVisibilityChanged(initialDeliverablesInReport, this.deliverablesInReport);
    }

    @HostListener('document:click', ['$event']) clickOut(event) {
        if (event.target.className.includes('mat-drawer-backdrop')) {
            event.stopPropagation();
            event.preventDefault();
            this.onCloseButtonClick();
        }
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event) {
        if (event.keyCode === 27) {
            this.onCloseButtonClick();
        }
    }

    isDeliverableConfigurationFlagEnabled() {
        return this.userInfo.featureFlags.includes('REPORTING_SETTINGS_DELIVERABLE_CONFIGURATION_SPECIFICS');
    }
}
