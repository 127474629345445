import {BarChart, BarChartData} from '@products/shared/chart-horizontal-bar/bar-chart.model';
import {FilterItem, FinancialPotentialFilter} from '../models/filter.model';
import {Injectable} from '@angular/core';
import * as numeral from 'numeral';
import {TranslateService} from '@ngx-translate/core';
import {FinancialPotentialService} from './financial-potential.service';
import {FinancialPotentialDeliverableView, Measures} from '../models/financial-potential.model';
import {MeasureMetric} from '@app/deliverables/financial-potential/models/measure-metric.enum';
import {defaultFinancialPotentialColors} from './../models/default-financial-potential-colors';
import {defaultPurchaseIntentBarchartOptions} from './../models/default-purchase-intent-barchart-options';
import {defaultTrialUnitsBarchartOptions} from './../models/default-trial-units-barchart-options';
import {defaultFrequencyBarchartOptions} from './../models/default-frequency-barchart-options';

/**
 * `ChartDataService` has operations for data tranformation for
 * financial Potential chart.
 *
 * @export
 * @class ChartDataService
 */
@Injectable()
export class FinancialPotentialChartDataService {

    /**
     * This Array is to store each of the frequency among favs values
     * @private
     */
    private allFrequencyValues = [];

    /**
     * This Array is to store each of the trial units among favs values
     * @private
     */
    private allTrialUnitsValues = [];

    /**
     * Creates an instance of ChartDataService.
     * @param translate
     * @param financialPotentialService
     */
    constructor(
        private translate: TranslateService,
        private financialPotentialService: FinancialPotentialService) {
    }

    /**
     * Returns Array of chart data for angular material table.
     * @param filter
     * @param financialPotential
     * @param metaInfo
     */
    public getChartData(filter: FinancialPotentialFilter, financialPotential: FinancialPotentialDeliverableView, metaInfo: Array<any>): Array<any> {
        const data = [];
        this.allFrequencyValues = [];
        this.allTrialUnitsValues = [];
        const isAlcohol = filter.datasets.length > 0;
        const retailData = filter.datasets.find(selected => selected.name === 'Retail');
        const isRetail = isAlcohol && retailData ? retailData.isSelected : false;
        const isConcepts = this.financialPotentialService.isConceptsCompareFilter(filter.compare);
        const selectedConcepts = this.financialPotentialService.selectedFilterItems(filter.concepts);
        const importedConcepts = filter.concepts.filter(it => it.isImported);
        selectedConcepts.sort(function (a, b) {
            return a.position - b.position;
        });
        const selectedSubgroups = this.financialPotentialService.selectedFilterItems(filter.subgroups);
        selectedSubgroups.sort(function (a, b) {
            return a.position - b.position;
        });
        const conceptsMap = this.getFilterItemMap(selectedConcepts);
        const subgroupsMap = this.getFilterItemMap(selectedSubgroups);
        const dbSubgroupSelected = !!selectedSubgroups.find(it => it.isDatabasable);
        let cellData, cellDataKey;
        const measures = this.filteredMeasuresData(financialPotential, isConcepts, dbSubgroupSelected, isAlcohol, isRetail, importedConcepts);
        measures.forEach((dataItem) => {
            cellData = {};
            cellDataKey = isConcepts ? conceptsMap[dataItem.conceptId] : subgroupsMap[dataItem.segmentId];
            cellData.position = cellDataKey.position;
            cellData.purchaseIntentBarChart = this.getBarChart(filter, dataItem, MeasureMetric.PURCHASE_INTENT);
            cellData.trialUnitsBarChart = this.getBarChart(filter, dataItem, MeasureMetric.TRIAL_UNITS_AMONG_FAVS);
            cellData.frequencyBarChart = this.getBarChart(filter, dataItem, MeasureMetric.FREQUENCY_AMONG_FAVS);
            cellData.name = cellDataKey.name;
            cellData.isImported = cellDataKey.isImported;
            cellData.reportName = cellDataKey.reportName;
            cellData.nsize = this.findbaseSize(financialPotential, dataItem.conceptId, dataItem.segmentId);
            cellData.purchaseIntentSubgroupStats = this.getSubgroupStatTestingValue(filter, dataItem, MeasureMetric.PURCHASE_INTENT);
            cellData.trialUnitsSubgroupStats = this.getSubgroupStatTestingValue(filter, dataItem, MeasureMetric.TRIAL_UNITS_AMONG_FAVS);
            cellData.frequencySubgroupStats = this.getSubgroupStatTestingValue(filter, dataItem, MeasureMetric.FREQUENCY_AMONG_FAVS);
            cellData.purchaseIntentConceptStats = this.getConceptsStatTestingValue(filter, dataItem, MeasureMetric.PURCHASE_INTENT);
            cellData.trialUnitsConceptStats = this.getConceptsStatTestingValue(filter, dataItem, MeasureMetric.TRIAL_UNITS_AMONG_FAVS);
            cellData.frequencyConceptStats = this.getConceptsStatTestingValue(filter, dataItem, MeasureMetric.FREQUENCY_AMONG_FAVS);
            cellData = this.setKMAData(isConcepts, cellData, dataItem);
            data.push(cellData);
        });
        data.sort(function (a, b) {
            return a.position - b.position;
        });
        const colHeaders = this.getColumnHeaders(financialPotential, metaInfo);
        return [data, colHeaders];
    }

    /**
     * Filtering the measures Data based on Alcohol / NON Alcohol and if alcohol then Retail / Public
     * @param financialPotential
     * @param isConceptsView
     * @param dbSubgroupSelected
     * @param isAlcohol
     * @param isRetailSelected
     * @param importedConcepts
     * @private
     */
    private filteredMeasuresData(financialPotential: FinancialPotentialDeliverableView, isConceptsView: Boolean, dbSubgroupSelected: Boolean,
                                 isAlcohol: Boolean, isRetailSelected?: Boolean, importedConcepts?: FilterItem[]) {
        let measureData;
        const hasImportedData = importedConcepts?.length > 0;
        // Measure Data filter based on Alcohol data selection
        if (isAlcohol && isRetailSelected) {
            measureData = financialPotential.measures.filter((dataItem) => dataItem.type === 'Retail');
        } else if (isAlcohol && !isRetailSelected) {
            measureData = financialPotential.measures.filter((dataItem) => dataItem.type === 'Public');
        } else {
            measureData = financialPotential.measures;
        }
        // Measure Data filter based on benchmarks and view selections
        if ((!isConceptsView && hasImportedData) || (isConceptsView && !dbSubgroupSelected)) {
            measureData = measureData.filter(dataItem => !importedConcepts.find(it => it.id === dataItem.conceptId));
        }
        return measureData;
    }

    /**
     * Find the n Size based on the correct ConceptId and SegmentId combination
     * @param financialPotential
     * @param conceptId
     * @param segmentId
     * @private
     */
    private findbaseSize(financialPotential: FinancialPotentialDeliverableView, conceptId: number, segmentId: number) {
        const baseSize = financialPotential.measuresBaseSizes.find(dataitem => dataitem.conceptId === conceptId && dataitem.segmentId === segmentId);
        return baseSize.sampleSize;
    }

    /**
     * get the Column Headers based on the CPS scores
     * @param financialPotential
     * @param metaInfo
     * @private
     */
    private getColumnHeaders(financialPotential: FinancialPotentialDeliverableView, metaInfo: Array<any>): Array<any> {
        const fpDeliverableConfigurations = metaInfo;
        let showPurchaseIntent, showCPS, showTrialUnits, showFrequency;
        let fpHasNoCpsData = financialPotential.measures.every(it => it.conceptPotentialScore === undefined);
        if (fpDeliverableConfigurations) {
            if (fpDeliverableConfigurations['purchaseIntent']) {
                showPurchaseIntent = MeasureMetric.PURCHASE_INTENT;
            }
            if (fpDeliverableConfigurations['trialUnits']) {
                showTrialUnits = MeasureMetric.TRIAL_UNITS_AMONG_FAVS;
            }
            if (fpDeliverableConfigurations['frequency']) {
                showFrequency = MeasureMetric.FREQUENCY_AMONG_FAVS;
            }
            if (fpDeliverableConfigurations['conceptPotentialScore'] && !fpHasNoCpsData) {
                showCPS = MeasureMetric.CONCEPT_POTENTIAL_SCORE;
            }
        }
        const allColumns = ['name', showPurchaseIntent, showTrialUnits, showFrequency, showCPS];
        return allColumns.filter(element => element !== undefined);
    }

    /*********************************************************** COLUMN SPECIFIC CHART DATA *********************************************/
    /**
     * Returns the bar chart object for the measure data item.
     * @param filter
     * @param measuresData
     * @param colHeader
     * @private
     */
    private getBarChart(filter: FinancialPotentialFilter, measuresData: Measures, colHeader: string): BarChart {
        let barChart: BarChart;
        barChart = {
            colors: Object.assign({}, defaultFinancialPotentialColors),
            options: this.getOptionsData(filter, measuresData, colHeader),
            series: [this.filterDataPoints(filter, measuresData, colHeader)]
        };
        return barChart;
    }

    /**
     * Returns the options for bar chart object creation required in getBrChart method
     * @param filter
     * @param dataItem
     * @param colHeader
     * @private
     */
    private getOptionsData(filter: FinancialPotentialFilter, dataItem: Measures, colHeader: string): any {
        let barChartOptions: any;
        switch (colHeader) {
            case MeasureMetric.PURCHASE_INTENT:
                const piBarChartOptions: any = Object.assign({}, defaultPurchaseIntentBarchartOptions);
                piBarChartOptions.bar.formatPattern = this.getFormatPattern(filter, colHeader);
                piBarChartOptions.bar.dataLabel = filter.show.dataLabels;
                piBarChartOptions.bar.dataLabelLine = filter.show.dataLabels;
                barChartOptions = piBarChartOptions;
                break;
            case MeasureMetric.TRIAL_UNITS_AMONG_FAVS:
                const trialUnitsBarChartOptions: any = Object.assign({}, defaultTrialUnitsBarchartOptions);
                trialUnitsBarChartOptions.bar.formatPattern = this.getFormatPattern(filter, colHeader);
                trialUnitsBarChartOptions.bar.domain.max = this.findTrialUnitsMaxValue(dataItem.trialUnitsData.trialUnitsAmongFavs);
                barChartOptions = trialUnitsBarChartOptions;
                break;
            case MeasureMetric.FREQUENCY_AMONG_FAVS:
                const frequencyBarChartOptions: any = Object.assign({}, defaultFrequencyBarchartOptions);
                frequencyBarChartOptions.bar.formatPattern = this.getFormatPattern(filter, colHeader);
                frequencyBarChartOptions.bar.domain.max = this.findFrequencyMaxValue(dataItem.frequencyData.frequencyAmongFavs);
                barChartOptions = frequencyBarChartOptions;
                break;
            default:
                break;
        }
        return barChartOptions;
    }

    /**
     * Filters a statement data item for data points to be added like mean, topThree.
     * @param filter
     * @param dataItem
     * @param colHeader
     * @private
     */
    private filterDataPoints(filter: FinancialPotentialFilter, dataItem: Measures, colHeader: string): BarChartData {
        const options = filter.show;
        const result = {};
        const id = `${dataItem.conceptId}-${dataItem.segmentId}`;
        const shouldAddDefinitelyBuy = options.topOneBox || options.topTwoBox || options.allFiveBoxes;
        const shouldAddProbablyBuy = options.topTwoBox || options.allFiveBoxes;
        const shouldAddRest = options.allFiveBoxes;
        switch (colHeader) {
            case MeasureMetric.PURCHASE_INTENT:
                this.addDataPoint(MeasureMetric.DEFINITELY_WOULD_BUY, id, shouldAddDefinitelyBuy, dataItem.purchaseIntentData.definitelyWouldBuy, result, filter);
                this.addDataPoint(MeasureMetric.PROBABLY_WOULD_BUY, id, shouldAddProbablyBuy, dataItem.purchaseIntentData.probablyWouldBuy, result, filter);
                this.addDataPoint(MeasureMetric.MIGHT_OR_MIGHT_NOT_BUY, id, shouldAddRest, dataItem.purchaseIntentData.mightOrMightNotBuy, result, filter);
                this.addDataPoint(MeasureMetric.PROBABLY_WOULD_NOT_BUY, id, shouldAddRest, dataItem.purchaseIntentData.probablyWouldNotBuy, result, filter);
                this.addDataPoint(MeasureMetric.DEFINITELY_WOULD_NOT_BUY, id, shouldAddRest, dataItem.purchaseIntentData.definitelyWouldNotBuy, result, filter);
                break;
            case MeasureMetric.TRIAL_UNITS_AMONG_FAVS:
                this.addDataPoint(MeasureMetric.TRIAL_UNITS_AMONG_FAVS, id, true, dataItem.trialUnitsData.trialUnitsAmongFavs, result, filter);
                break;
            case MeasureMetric.FREQUENCY_AMONG_FAVS:
                this.addDataPoint(MeasureMetric.FREQUENCY_AMONG_FAVS, id, true, dataItem.frequencyData.frequencyAmongFavs, result, filter);
                break;
            default:
                break;
        }
        return result;
    }

    /**
     * Returns tooltip text based on the filter and value.
     *
     * @param {string} metric the metric key
     * @param {number} value the value
     * @param {FinancialPotentialFilter} filter the filter
     * @private
     * @method
     */
    private getTooltip(metric: string, value: number, filter: FinancialPotentialFilter): string {
        let tooltip: string;
        const pattern: string = this.getFormatPattern(filter, MeasureMetric.PURCHASE_INTENT);
        const formatted: string = numeral(value).format(pattern);
        switch (metric) {
            case MeasureMetric.DEFINITELY_WOULD_BUY:
                tooltip = this.translate.instant('quick.predict.deliverables.financial-potential.legend.row2.definitelyWouldBuy', {value: formatted});
                break;
            case MeasureMetric.PROBABLY_WOULD_BUY:
                tooltip = this.translate.instant('quick.predict.deliverables.financial-potential.legend.row2.probablyWouldBuy', {value: formatted});
                break;
            case MeasureMetric.MIGHT_OR_MIGHT_NOT_BUY:
                tooltip = this.translate.instant('quick.predict.deliverables.financial-potential.legend.row2.might', {value: formatted});
                break;
            case MeasureMetric.PROBABLY_WOULD_NOT_BUY:
                tooltip = this.translate.instant('quick.predict.deliverables.financial-potential.legend.row2.probablyWouldNotBuy', {value: formatted});
                break;
            case MeasureMetric.DEFINITELY_WOULD_NOT_BUY:
                tooltip = this.translate.instant('quick.predict.deliverables.financial-potential.legend.row2.definitelyWouldNotBuy', {value: formatted});
                break;
            default:
                tooltip = 'none';
        }
        return tooltip;
    }

    /**
     * Returns the Subgroup stat testing label for the data item.
     * @param filter
     * @param dataItem
     * @param colHeader
     * @private
     */
    private getSubgroupStatTestingValue(filter: FinancialPotentialFilter, dataItem: Measures, colHeader: string): string {
        let statString: string;
        const showFilter = filter.show;
        const subgroupFilterIds = filter.subgroups.filter(subgroup => subgroup.isSelected).map(subgroup => subgroup.id);
        switch (colHeader) {
            case MeasureMetric.PURCHASE_INTENT:
                let piSubgroupIds = [];
                piSubgroupIds = showFilter.topOneBox && dataItem.purchaseIntentData.topOneBoxSubgroupStat ? dataItem.purchaseIntentData.topOneBoxSubgroupStat : piSubgroupIds;
                piSubgroupIds = showFilter.topTwoBox && dataItem.purchaseIntentData.topTwoBoxSubgroupStat ? dataItem.purchaseIntentData.topTwoBoxSubgroupStat : piSubgroupIds;
                statString = this.financialPotentialService.getAlphabetsForStats(piSubgroupIds, subgroupFilterIds);
                break;
            case MeasureMetric.TRIAL_UNITS_AMONG_FAVS:
                let trialUnitsStats = [];
                trialUnitsStats = dataItem.trialUnitsData.subgroupStat ? dataItem.trialUnitsData.subgroupStat : trialUnitsStats;
                statString = this.financialPotentialService.getAlphabetsForStats(trialUnitsStats, subgroupFilterIds);
                break;
            case MeasureMetric.FREQUENCY_AMONG_FAVS:
                let frequencyStats = [];
                frequencyStats = dataItem.frequencyData.subgroupStat ? dataItem.frequencyData.subgroupStat : frequencyStats;
                statString = this.financialPotentialService.getAlphabetsForStats(frequencyStats, subgroupFilterIds);
                break;
            default:
                break;
        }
        return statString;
    }

    /**
     * Returns the Concept stat testing label for the data item.
     * @param filter
     * @param dataItem
     * @param colHeader
     * @private
     */
    private getConceptsStatTestingValue(filter: FinancialPotentialFilter, dataItem: Measures, colHeader: string): string {
        let statString: string;
        const conceptFilterIds = filter.concepts.filter(concept => concept.isSelected && !concept.isImported).map(concept => concept.id);
        switch (colHeader) {
            case MeasureMetric.PURCHASE_INTENT:
                let piConceptIds = [];
                piConceptIds = filter.show.topOneBox && dataItem.purchaseIntentData.topOneBoxConceptStat ? dataItem.purchaseIntentData.topOneBoxConceptStat : piConceptIds;
                piConceptIds = filter.show.topTwoBox && dataItem.purchaseIntentData.topTwoBoxConceptStat ? dataItem.purchaseIntentData.topTwoBoxConceptStat : piConceptIds;
                statString = this.financialPotentialService.getAlphabetsForStats(piConceptIds, conceptFilterIds);
                break;
            case MeasureMetric.TRIAL_UNITS_AMONG_FAVS:
                let trialUnitsStats = [];
                trialUnitsStats = dataItem.trialUnitsData.conceptStat ? dataItem.trialUnitsData.conceptStat : trialUnitsStats;
                statString = this.financialPotentialService.getAlphabetsForStats(trialUnitsStats, conceptFilterIds);
                break;
            case MeasureMetric.FREQUENCY_AMONG_FAVS:
                let frequencyStats = [];
                frequencyStats = dataItem.frequencyData.conceptStat ? dataItem.frequencyData.conceptStat : frequencyStats;
                statString = this.financialPotentialService.getAlphabetsForStats(frequencyStats, conceptFilterIds);
                break;
            default:
                break;
        }
        return statString;
    }

    /**
     * set the chart data for NON-Alcohol Studies
     * @param isConcepts
     * @param cellData
     * @param dataItem
     * @private
     */
    private setKMAData(isConcepts: boolean, cellData: any, dataItem: Measures): any {
        cellData.conceptPotentialScore = dataItem.conceptPotentialScore;
        cellData.primaryCompetitiveSet = dataItem.primaryCompetitiveSet;
        cellData.primaryCompetitiveSetBaseSize = dataItem.primaryCompetitiveSetBaseSize;
        cellData.conceptPotentialRankScore = this.getQuantileScore(isConcepts, dataItem, MeasureMetric.CONCEPT_POTENTIAL_SCORE);
        cellData.purchaseIntentRankScore = this.getQuantileScore(isConcepts, dataItem, MeasureMetric.PURCHASE_INTENT);
        cellData.trialUnitsRankScore = this.getQuantileScore(isConcepts, dataItem, MeasureMetric.TRIAL_UNITS_AMONG_FAVS);
        cellData.frequencyRankScore = this.getQuantileScore(isConcepts, dataItem, MeasureMetric.FREQUENCY_AMONG_FAVS);
        return cellData;
    }

    /**
     * This will emit the QuantileScore text to be shown on the bar cells if its concepts view
     * @param isConceptView
     * @param dataItem
     * @param colHeader
     * @private
     */
    private getQuantileScore(isConceptView: boolean, dataItem: Measures, colHeader: string) {
        let quantileScore: string;
        switch (colHeader) {
            case MeasureMetric.PURCHASE_INTENT:
                quantileScore = isConceptView ? this.financialPotentialService.getQuintileRank(dataItem.purchaseIntentData.rankScore) : '';
                break;
            case MeasureMetric.TRIAL_UNITS_AMONG_FAVS:
                quantileScore = isConceptView ? this.financialPotentialService.getQuintileRank(dataItem.trialUnitsData.rankScore) : '';
                break;
            case MeasureMetric.FREQUENCY_AMONG_FAVS:
                quantileScore = isConceptView ? this.financialPotentialService.getQuintileRank(dataItem.frequencyData.rankScore) : '';
                break;
            case MeasureMetric.CONCEPT_POTENTIAL_SCORE:
                quantileScore = isConceptView ? this.financialPotentialService.getQuintileRank(dataItem.conceptPotentialRankScore) : '';
                break;
            default:
                break;
        }
        return quantileScore;
    }

    /****************** COMMON METHODS FOR CHART DATA CREATION ************************/

    /**
     * Adds a data point if the `shouldAdd` flag set to true.
     *
     * @private
     * @param {string} key
     * @param {string} id
     * @param {boolean} shouldAdd
     * @param {number} value
     * @param {*} data
     * @param {FinancialPotentialFilter} filter
     * @memberof ChartDataService
     */
    private addDataPoint(key: string, id: string, shouldAdd: boolean, value: number, data: any, filter: FinancialPotentialFilter): void {
        if (shouldAdd) {
            value = value && (key === MeasureMetric.TRIAL_UNITS_AMONG_FAVS || key === MeasureMetric.FREQUENCY_AMONG_FAVS) ? value : value / 100;
            data[key] = {
                id: `${key}-${id}`,
                value: value,
                tooltip: this.getTooltip(key, value, filter)
            };
        }
    }

    /**
     * Returns numeraljs display pattern for the numeric value.
     *
     * @private
     * @param {FinancialPotentialFilter} filter
     * @param colHeader
     * @returns {string}
     * @memberof ChartDataService
     */
    private getFormatPattern(filter: FinancialPotentialFilter, colHeader: string): string {
        let pattern = '0';
        if (colHeader === MeasureMetric.PURCHASE_INTENT) {
            pattern = pattern + '%';
        } else {
            pattern = '0.0';
        }
        return pattern;
    }

    /**
     * Returns map of filter items.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {{[key: number]: FilterItem}}
     * @memberof ChartDataService
     */
    private getFilterItemMap(items: Array<FilterItem>): { [key: number]: FilterItem } {
        const itemsMap: { [key: number]: FilterItem } = {};
        items.forEach(item => {
            itemsMap[item.id] = item;
        });
        return itemsMap;
    }

    /**
     * This will find the Maximum Value from array of data items and round it up to next big 10s digit
     * Example: 10,20,30,40 etc
     * @param item
     * @private
     */
    private findFrequencyMaxValue(item: number): number {
        this.allFrequencyValues.push(item);
        const maxValue = Math.max(...this.allFrequencyValues);
        return Math.ceil(maxValue / 10.0) * 10;
    }

    /**
     * This will find the Maximum Value from array of data items and round it up to next big 5s digit
     * Example: 5,10,15,20 etc
     * @param item
     * @private
     */
    private findTrialUnitsMaxValue(item: number): number {
        this.allTrialUnitsValues.push(item);
        const maxValue = Math.max(...this.allTrialUnitsValues);
        return Math.ceil(maxValue / 5.0) * 5;
    }


}
