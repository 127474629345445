import {Component, OnInit} from '@angular/core';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {Concept} from '@platform/models/concept.model';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {ExportPngService} from '@platform/services/export-png.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {UserService} from '@platform/services/user.service';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import {PriceMentionsFilter} from './models/filter.model';
import {ImagePath, PriceMentionsDeliverableView} from './models/price-mentions.model';
import {PriceMentionsService} from './services/price-mentions.service';
import {RouterService} from '@platform/services/router.service';
import {ReportService} from '@platform/services/report.service';
import {UserViewService} from '@platform/services/user-view.service';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {FilterService} from '@platform/services/filter.service';
import {InsightService} from '@platform/insights/insights.service';
import {DeliverableInfoService} from '@platform/services/deliverable-info.service';
import {Report} from '@platform/models/report.model';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {UserView} from '@platform/models/user-view.model';

@Component({
    selector: 'ns-qpa-price-mentions',
    templateUrl: './price-mentions.component.html',
    styleUrls: ['./price-mentions.component.scss']
})
export class PriceMentionsComponent implements OnInit {

    /**
     * ScoreCard Concept object for Price Mentions.
     *
     * @type {Concept}
     * @member PriceMentionsComponent
     */
    public scoreCardConcept: Concept;

    /**
     * Price Mentions deliverable view filter object.
     *
     * @type {PriceMentionsFilter}
     * @member PriceMentionsComponent
     */
    public filter: PriceMentionsFilter;

    /**
     * The PriceMentionsDeliverableView.
     * @type {PriceMentionsDeliverableView} PriceMentionsDeliverableView
     * @member PriceMentionsComponent
     */
    public priceMentionsDeliverableView: PriceMentionsDeliverableView;

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @member PriceMentionsComponent
     */
    public subscriptions: Array<Subscription>;

    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     * @member PriceMentionsComponent
     */
    public isInsightEnable = false;

    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     * @member PriceMentionsComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * Spinner.
     *
     * @type {Boolean}
     * @member PriceMentionsComponent
     */
    public displayProgressSpinner = false;

    /**
     * Disable Insights Button.
     *
     * @type {Boolean}
     * @member PriceMentionsComponent
     */
    public disableBtn: boolean;

    /**
     * Internal User
     *
     * @type {Boolean}
     * @member PriceMentionsComponent
     */
    public isInternalUser: Boolean;

    /**
     * Image path
     *
     * @member PriceMentionsComponent
     */
    public imagePath: ImagePath;

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;
    public report: Report;
    public deliverableInfos: Array<DeliverableInfo>;
    public deliverableViews: Array<DeliverableView>;
    public deliverableType = DeliverableType.PRICE_MENTIONS.type;
    public userViews: Array<UserView>;


    constructor(
        private deliverableInsightService: DeliverableInsightService,
        private exportPNGService: ExportPngService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private reportService: ReportService,
        private userViewService: UserViewService,
        private routeService: RouterService,
        private deliverableViewService: DeliverableViewService,
        private filterService: FilterService,
        private insightService: InsightService,
        private deliverableInfoService: DeliverableInfoService,
        private priceMentionsService: PriceMentionsService
    ) {
        this.subscriptions = [];
    }

    /**
     * Initialize component
     *
     * @member PriceMentionsComponent
     */
    ngOnInit(): void {

        const insightId = this.routeService.getQueryParam('insightId');
        const subscription = combineLatest([
            this.reportService.get(),
            this.userService.getUser(),
            this.deliverableViewService.getDeliverableViews(DeliverableType.PRICE_MENTIONS.type)
        ]).subscribe(([report, user, deliverableViews]) => {
            this.report = report;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.isInternalUser = user.isInternalUser;
            this.deliverableViews = deliverableViews;
            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
            forkJoin([
                this.priceMentionsService.getDefaultFilterData(deliverableViews),
                this.userViewService.fetchReportUserViewsFromAPI(this.report.id),
                this.insightService.getInsightFilterData<PriceMentionsFilter>(report.id, insightId),

            ]).subscribe(([defaultViewFilters, userViews, insightFilter]) => {
                this.userViews = this.userViewService.setupUserViews(this.report.id, DeliverableType.PRICE_MENTIONS.type, userViews, defaultViewFilters, insightFilter);
                const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));

                this.subscriptions.push(combineLatest([
                    this.priceMentionsService.getPriceMentionsFilter(),
                    this.priceMentionsService.getPriceMentions(),
                ]).subscribe(([filter, priceMentions]) => {
                    this.filter = filter;
                    this.priceMentionsDeliverableView = priceMentions;
                    this.imagePath = this.priceMentionsService.getImagePath(this.priceMentionsDeliverableView);
                    this.filterService.update(this.filter);
                    if (this.isInsightEnable && this.isAutomatedHeadlinesEnabled) {
                        this.openInsightCreationForm();
                    }
                }));

            });

        });
        this.subscriptions.push(subscription);
    }

    selectUserView(userView: UserView): void {
        this.filter = userView.filter as PriceMentionsFilter;
        this.filterService.update(userView.filter);
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG();
        this.spinnerService.getSpinnerObs().subscribe((loading) => this.displayProgressSpinner = loading);
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        this.isInsightEnable = true;
        const insightHTMLData = this.deliverableInsightService.getInsightHTML();
        this.deliverableData = {
            deliverable: {
                deliverableViewId: this.priceMentionsDeliverableView.id,
                filter: this.filter,
                insightHTML: insightHTMLData
            }
        };
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * Cleanup hook.
     *
     * @member PriceMentionsComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }
}
