import {combineLatest, debounceTime, Subscription} from 'rxjs';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {FactorsService} from '@app/deliverables/factors/services/factors.service';
import {FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {ColumnsViewService} from '@app/deliverables/factors/services/concepts-view.service';
import {ReportService} from '@platform/services/report.service';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {ProductDeliverableViewService} from '@platform/services/product-deliverable-view.service';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {FactorsFilter} from '@app/deliverables/factors/models/filter.model';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {UserService} from '@platform/services/user.service';
import {switchMap, take} from 'rxjs/operators';
import {Report} from '@platform/models/report.model';
import {FilterService} from "@platform/services/filter.service";

/**
 * `<ns-concepts-view>` component builds factors for success deliverable for concept view.
 *
 * @example
 * <ns-concepts-view></ns-concepts-view>
 *
 * @export
 * @class ConceptsViewComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'ns-concepts-view',
    templateUrl: './concepts-view.component.html',
    styleUrls: ['./concepts-view.component.scss']
})
export class ConceptsViewComponent implements OnInit, OnDestroy {

    @Output() hasDeliverableData: EventEmitter<any> = new EventEmitter<boolean>();

    /**
     * Factors deliverable view data.
     *
     * @type {FactorsDeliverableView}
     * @member ConceptsViewComponent
     */
    public factorsDeliverableView: FactorsDeliverableView;

    /**
     * Array of subscriptions for cleanup.
     *
     * @private
     * @type {Array<Subscription>}
     * @memberof ConceptsViewComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * All column headers for the Factors for success table for concept view.
     *
     * @type {Array<any>}
     * @member ConceptsViewComponent
     */
    public colHeaders: Array<any>;

    /**
     * Array of displayed columns keys.
     *
     * @type {Array<string>}
     * @member ConceptsViewComponent
     */
    public displayedColumns: string[];

    /**
     * Datasource object for the angular material table.
     *
     * @member ConceptsViewComponent
     */
    public dataSource: MatTableDataSource<any>;

    /**
     * Internal user check
     *
     * @type {Boolean}
     * @memberof ConceptsViewComponent
     */
    public isInternalUser: Boolean;

    /**
     * Is the Report having any Alcohol DataSet information
     *
     * @type {boolean}
     * @member ConceptsViewComponent
     */
    public isAlcoholStudy = false;

    /**
     * Is the Report having any Cannabis DataSet information
     *
     * @type {boolean}
     * @member ConceptsViewComponent
     */
    public isCannabisStudy = false;

    /**
     * FactorsFilter Data
     *
     * @member FactorsFilter
     */
    public filter: FactorsFilter;

    /**
     * Array of the DeliverableView
     *
     * @member DeliverableView
     */
    public deliverableViews: DeliverableView;

    /**
     * Report Data
     *
     * @member Report
     */
    public report: Report;

    /**
     * Check if data exists in the chart data of dataSource
     */
    public hasData: boolean;

    private concepts: Array<any>;

    public reload: boolean;

    private configIsUpdated: boolean;

    private factorsIsInitialized: boolean;

    private subgroupDisplayNames: Array<any>;

    /**
     * Creates an instance of ConceptsViewComponent for Concepts view and initialize
     * the component data.
     *
     * @constructor
     * @param {FactorsService} factorsService
     * @param {ColumnsViewService} columnsViewService
     * @param {ReportService} reportService
     * @param {MixpanelService} mixpanelService
     * @param userService
     * @param productDeliverableViewService
     * @param deliverableViewService
     * @member ConceptsViewComponent
     */
    constructor(
        private factorsService: FactorsService,
        private columnsViewService: ColumnsViewService,
        private reportService: ReportService,
        private mixpanelService: MixpanelService,
        private userService: UserService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private deliverableViewService: DeliverableViewService,
        private filterService: FilterService
    ) {
        this.subscriptions = [];
        this.displayedColumns = [];
        this.reload = false;
        this.configIsUpdated = this.factorsIsInitialized = false;
    }

    /**
     * Initialize the table component for FFS
     *
     * @member ConceptsViewComponent
     */
    ngOnInit(): void {
        this.loadComponent();
        this.reportService.factorsConfigChanged$.subscribe((configChanged) => {
            if(configChanged) {
                this.productDeliverableViewService.clearCache();
                this.configIsUpdated = true;
                this.loadComponent();
            }
        });
    }

    loadComponent(): void {
        const report$ = this.reportService.get();
        const user$ = this.userService.getUser();
        const filter$ = this.factorsService.getFactorsFilter();
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(DeliverableType.FACTORS.type);
        this.subgroupDisplayNames = this.factorsService.getFlyoutConfigItems().map(c => ({id: c.id, subgroupDisplayName: c.subgroupDisplayName}));
        const subscription = combineLatest([report$, filter$, user$, deliverableViews$])
            .pipe(switchMap((results) => {
                const [report, filters, user, deliverableViews] = [...results];
                this.report = report;
                this.report.projectType && this.report.projectType.toLowerCase() === 'alcohol' ? this.isAlcoholStudy = true : this.isAlcoholStudy = false;
                this.report.projectType && this.report.projectType.toLowerCase() === 'cannabis' ? this.isCannabisStudy = true : this.isCannabisStudy = false;
                this.deliverableViews = deliverableViews.find(it => it.viewName === 'concept');
                this.isInternalUser = user.isInternalUser;
                this.filter = filters;
                return this.productDeliverableViewService.get<FactorsDeliverableView>(report.id, this.deliverableViews.productViewId);
            })).subscribe((factors) => {
                this.concepts = factors.concepts;
                if(!this.factorsIsInitialized) {
                    this.loadInitFactorsConfig();
                } else if(this.configIsUpdated) {
                    this.configIsUpdated = false;
                    this.updateFilterOnConfigChange();
                } else if(!this.factorsFilterConfigMatch()) {
                    this.updateFilterOnConfigChange();
                }
                const [colHeaders, factorsDataConcepts] = this.columnsViewService.getConceptsChartData(this.filter, factors, this.isAlcoholStudy, this.isCannabisStudy);
                this.hasData = factorsDataConcepts.length > 0;
                this.hasDeliverableData.emit(this.hasData);
                this.dataSource = new MatTableDataSource(factorsDataConcepts);
                this.displayedColumns = Object.keys(this.dataSource.data.length > 0 && this.dataSource.data[0]);
                this.colHeaders = colHeaders;
            });
        this.subscriptions.push(subscription);
    }

    factorsFilterConfigMatch(): boolean {
        let factorsFilterMatch = true;
        let factorsInFilter = this.filter.show.factorsOptions.map(factor => factor.id);
        let factorsInConfig = this.factorsService.getFactorsConfiguration();
        let excludedKmaFactors = this.factorsService.getExcludedKmaFactors();
        let defFlyoutConfigItems = this.factorsService.defaultFilter.show.factorsOptions.map(factor => factor.id);
        let currentConfigItems = defFlyoutConfigItems.filter(factor => (factorsInConfig[factor] && !excludedKmaFactors.includes(factor)));
        if(currentConfigItems.length !== factorsInFilter.length) {
            return false;
        }
        currentConfigItems.forEach(currConfigItem => {
            if(!factorsInFilter.includes(currConfigItem)) {
                factorsFilterMatch = false;
            }
        })
        return factorsFilterMatch;
    }

    updateFilterOnConfigChange(): void {
        this.factorsService.setFactorsConfiguration(this.report.id);
        this.factorsService.setExcludedKmaFactors(this.concepts);
        this.filter = JSON.parse(JSON.stringify(this.filter));
        let factorFilterSelection = {};
        this.filter.show.factorsOptions.forEach(fo => {
            factorFilterSelection[fo.id] = fo.isSelected;
        });
        this.filter.show.factorsOptions = [];
        let defFlyoutConfigItems = this.factorsService.defaultFilter.show.factorsOptions;
        defFlyoutConfigItems.forEach(c => {
            if(this.factorsService.getFactorsConfiguration()[c.id] &&
                !this.filter.show.factorsOptions.map(f => f.id).includes(c.id)) {
                if(this.filter.deliverableViewType === 'concept')
                    this.filter.show.factorsOptions.push({id: c.id, name: c.name, isSelected: factorFilterSelection[c.id]??true});
                else if(this.filter.deliverableViewType === 'subgroup')
                    this.filter.show.factorsOptions.push({id: c.id, name: this.subgroupDisplayNames.find(s => s.id === c.id).subgroupDisplayName, isSelected: factorFilterSelection[c.id]??true});
            }
        });
        this.filter.show.factorsOptions = this.filter.show.factorsOptions.filter(options => !this.factorsService.getExcludedKmaFactors().includes(options.id.toString()));
        this.filterService.update(this.filter);
    }

    loadInitFactorsConfig() {
        this.factorsService.setFactorsConfiguration(this.report.id);
        this.factorsService.setExcludedKmaFactors(this.concepts);
        this.filter = JSON.parse(JSON.stringify(this.filter));
        this.filter.show.factorsOptions = this.filter.show.factorsOptions.filter(fo => this.factorsService.getFactorsConfiguration()[fo.id] != undefined && this.factorsService.getFactorsConfiguration()[fo.id]);
        this.filter.show.factorsOptions = this.filter.show.factorsOptions.filter(options => !this.factorsService.getExcludedKmaFactors().includes(options.id.toString()));
        this.filterService.update(this.filter);
        this.factorsIsInitialized = true;
    }

    /**
     * Returns unique id for the loop to be refreshed.
     *
     * @param {number} index the performance table column for loop index
     * @param {any} item the colHeader object
     */
    public trackItem(index: number, item: any): string {
        return `${index}-${item.id ? item.id : 0}`;
    }

    /**
     * Track factor hover event on mixpanel.
     *
     * @member ConceptsViewComponent
     */
    public trackFactor(): void {
        this.mixpanelService.track(this.mixpanelService.getCurrentFeatureLabel(), MixpanelEvent.factorClick);
    }

    /**
     * Cleanup hook.
     *
     * @member ConceptsViewComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
