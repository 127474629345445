import {Concept} from '@platform/models/concept.model';
import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {FilterService} from '@platform/services/filter.service';
import {SurveyQuestionService} from './services/survey-question.service';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SurveyQuestionDeliverableView} from './models/survey-question.model';
import {FilterItem, SurveyQuestionFilter} from './models/filter.model';
import {combineLatest, forkJoin, Subscription} from 'rxjs';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {SurveyQuestionFileService} from './services/survey-question-file.service';
import {ExportPngService} from '@platform/services/export-png.service';
import {MatSort, Sort} from '@angular/material/sort';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {UserService} from '@platform/services/user.service';
import {SurveyQuestionsMetaInfo} from './models/view-meta-info.model';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {SpinnerService} from '@platform/services/spinner.service';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {skipWhile} from 'rxjs/operators';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import {DeliverableViewService} from "@platform/services/deliverable-view.service";
import {MAT_LEGACY_SELECT_CONFIG as MAT_SELECT_CONFIG} from '@angular/material/legacy-select';
import {DeliverableView} from "@platform/models/deliverable-view.model";
import {DeliverableInfo} from "@platform/models/deliverable-info.model";
import {DeliverableInfoService} from "@platform/services/deliverable-info.service";
import {ReportService} from "@platform/services/report.service";
import {InsightService} from "@platform/insights/insights.service";
import {Report} from '@platform/models/report.model';
import {RouterService} from "@platform/services/router.service";
import {StrengthWatchoutsFilter} from "@app/deliverables/strengths-watchouts/models/strength-watchouts-filter.model";
import {UserViewService} from "@platform/services/user-view.service";
import {UserView} from "@platform/models/user-view.model";
import {ProductDeliverableViewService} from "@platform/services/product-deliverable-view.service";

/**
 * `<ns-survey-question>` component builds surveyQuestion deliverable for both
 * concepts and subgroups deliverable views.
 *
 * @example
 * <ns-survey-question></ns-survey-question>
 *
 * @export
 * @class SurveyQuestionsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'ns-survey-question',
    templateUrl: './survey-question.component.html',
    styleUrls: ['./survey-question.component.scss'],
    providers: [
        {
            provide: MAT_SELECT_CONFIG,
            useValue: {overlayPanelClass: 'full-width-panel-backdrop'},
        },
    ],
})
export class SurveyQuestionComponent implements OnInit, OnDestroy, ScoreCardView {
    /**

     * SurveyQuestion deliverable view data.
     *
     * @type {SurveyQuestionDeliverableView}
     * @memberOf SurveyQuestionComponent
     */
    public surveyQuestionData: SurveyQuestionDeliverableView;

    /**
     * Survey Question deliverable view filter object.
     *
     * @type {SurveyQuestionFilter}
     * @memberOf SurveyQuestionComponent
     */
    public filter: SurveyQuestionFilter;

    /**
     * Survey Question List
     * @memberOf SurveyQuestionComponent
     */
    public questionsList: Array<any>;

    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @memberOf SurveyQuestionComponent
     */
    public subscriptions: Array<Subscription>;

    /**
     * Selected Question String
     * @memberOf SurveyQuestionComponent
     */
    public questionSelect: any;

    /**
     * Datasource object for the angular material table.
     *
     * @memberOf SurveyQuestionComponent
     */
    public dataSource: MatTableDataSource<any>;

    /**
     * All column headers for the Survey Question table.
     *
     * @type {Array<any>}
     * @memberOf SurveyQuestionComponent
     */
    public colHeaders: Array<any>;

    /**
     * Array of displayed columns keys.
     *
     * @type {Array<string>}
     * @memberOf SurveyQuestionComponent
     */
    public displayedColumns: Array<string>;

    /**
     * Selected Question Text
     *
     * @type {Array<string>}
     * @memberOf SurveyQuestionComponent
     */
    public selectedQuestionText: string;

    /**
     * Selected Question Text
     *
     * @type {Array<string>}
     * @memberOf SurveyQuestionComponent
     */
    public selectedQuestionName: string;
    /**
     * Selected Question Text
     *
     * @type {Array<string>}
     * @memberOf SurveyQuestionComponent
     */
    public selectedQuestionTootip: string;

    /**
     * Array of static column indexes passed to `<ns-swipe-table>`
     * component.
     *
     * @type {number[]}
     * @memberOf SurveyQuestionComponent
     */
    public staticColumns: number[];

    /**
     * Array of all column widths passed to `<ns-swipe-table>`
     * component.
     *
     * @type {number[]}
     * @memberOf SurveyQuestionComponent
     */
    public columnWidths: number[];

    /**
     * deliverable Data for insights creations.
     *
     * @type {number[]}
     * @memberOf SurveyQuestionComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * check insights enable/disable.
     *
     * @type {number[]}
     * @memberOf SurveyQuestionComponent
     */
    public isInsightEnable = false;

    /**
     * internal user check`
     * component.
     *
     * @type {number[]}
     * @memberOf SurveyQuestionComponent
     */
    public isInternalUser: Boolean;

    /**
     * Spinner.
     *
     * @type {Array<any>}
     * @memberOf SurveyQuestionComponent
     */
    public displayProgressSpinner = false;

    /**
     * View child for angular material table sorting.
     *
     * @type {MatSort}
     * @memberOf AttributesComponent
     */
    @ViewChild(MatSort) sort: MatSort;

    /**
     * Meta info for row highlight.
     *
     * @type {SurveyQuestionDeliverableView}
     * @memberOf SurveyQuestionComponent
     */
    public viewSurveyQuestionsMetaInfo: SurveyQuestionsMetaInfo;

    public disableBtn: boolean;

    /**
     * ScoreCard Concept object for SW.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;

    public selectedView: string;

    /**
     * Autocomplete search for question filtering
     */
    public searchValue: string;

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

    public filteredQuestionsList: any[] = [];
    public deliverableViews: Array<DeliverableView>;

    public deliverableInfos: Array<DeliverableInfo>;

    public report: Report;

    public userViews: Array<UserView>;

    public deliverableType = DeliverableType.SURVEY_QUESTION.type;

    private configChanged: Boolean;

    /**
     * Creates an instance of SurveyQuestionComponent and initialize
     * the component data.
     *
     * @constructor
     * @param {SurveyQuestionService} surveyQuestionService
     * @param surveyQuestionFileService
     * @param filterService
     * @param exportPNGService
     * @param productServiceFactory
     * @param spinnerService
     * @param viewMetaInfoService
     * @param userService
     * @param userViewService
     * @param deliverableInsightService
     * @memberOf SurveyQuestionComponent
     */
    constructor(
        private surveyQuestionService: SurveyQuestionService,
        private surveyQuestionFileService: SurveyQuestionFileService,
        private filterService: FilterService,
        private exportPNGService: ExportPngService,
        private spinnerService: SpinnerService,
        private viewMetaInfoService: ViewMetaInfoService,
        private userService: UserService,
        private deliverableInsightService: DeliverableInsightService,
        private deliverableInfoService: DeliverableInfoService,
        private translate: TranslateService,
        private mixpanelService: MixpanelService,
        private deliverableViewService: DeliverableViewService,
        private reportService: ReportService,
        private insightService: InsightService,
        private routerService: RouterService,
        private productDeliverableViewService: ProductDeliverableViewService,
        private userViewService: UserViewService
    ) {
        this.questionsList = [];
        this.subscriptions = [];
        this.displayedColumns = [];
        this.staticColumns = [];
        this.userViews = [];
        this.configChanged = false;
    }

    /**
     * Initialize the Survey Question component view.
     *
     * @memberOf SurveyQuestionComponent
     */
    ngOnInit(): void {
        this.init();
        this.reportService.reloadDeliverable.subscribe(() => {
            this.configChanged = true;
            this.productDeliverableViewService.clearCache();
            this.init();
        });
    }

    init(): void {
        const insightId = this.routerService.getQueryParam('insightId');
        const filter$ = this.surveyQuestionService.getSurveyQuestionFilter();
        const surveyQuestion$ = this.surveyQuestionService.getSurveyQuestion();
        const deliverableType = DeliverableType.SURVEY_QUESTION.type;
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(deliverableType);
        const viewMetaInfo$ = this.viewMetaInfoService.get<SurveyQuestionsMetaInfo>(deliverableType);

        const subscription = combineLatest([
            this.reportService.get(),
            this.userService.getUser()
        ]).subscribe(([report, user]) => {
            this.report = report;
            this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
            this.isInternalUser = user.isInternalUser;
            this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
            //load saved user views(filter) if they exist
            forkJoin([
                this.userViewService.fetchReportUserViewsFromAPI(this.report.id),
                this.insightService.getInsightFilterData<StrengthWatchoutsFilter>(report.id, insightId),
                this.surveyQuestionService.getDefaultFilterData(this.scoreCardConcept)
            ]).subscribe(([userViews, insightFilter, defaultViewFilters]) => {
                this.userViews = this.userViewService.setupUserViews(this.report.id, this.deliverableType, userViews, defaultViewFilters, insightFilter);
                const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
                if(!this.configChanged) {
                    this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));
                }
                this.subscriptions.push(combineLatest([surveyQuestion$, filter$, viewMetaInfo$, deliverableViews$])
                    .pipe(skipWhile(([surveyQuestions, filter, viewMetaInfo, deliverableViews]) => viewMetaInfo == null))
                    .subscribe(([surveyQuestions, filter, viewMetaInfo, deliverableViews]) => {
                        this.deliverableViews = deliverableViews;
                        this.filter = filter;
                        this.filteredQuestionsList = this.questionsList = this.surveyQuestionService.newDefaultQuestions;
                        this.questionSelect = (this.filteredQuestionsList.find(ques => ques.isSelected === true) ?? this.filteredQuestionsList[0])?.id;
                        this.onQuestionSelect(surveyQuestions, filter, viewMetaInfo);
                        this.disableBtn = this.isConceptSubgroupExists(filter);
                        this.selectedView = filter.compare.find((selected) => selected.isSelected).name === 'Concepts' ? 'Concepts' : 'Subgroups';
                        this.configChanged = false;
                        if (this.isInsightEnable && this.isAutomatedHeadlinesEnabled) {
                            this.openInsightCreationForm();
                        }
                    }));
            });
        });
        this.subscriptions.push(subscription);
    }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.filter = userView.filter as SurveyQuestionFilter;
        this.disableBtn = this.isConceptSubgroupExists(this.filter);
        this.filterService.update(userView.filter);
    }

    selectQuestion(selectedQuestionId: any) {
        this.filterService.update({
            ...this.filter,
            questions: this.updateQuestions(selectedQuestionId).map((question) => {
                return {
                    ...question,
                    isSelected: question.id === selectedQuestionId,
                };
            }),
        });
    }

    updateQuestions(selectedQuestionId) {
        let filterQuestions = [];
        this.filter.questions.forEach(ques => filterQuestions.push(ques));
        this.filteredQuestionsList.map(q => {
            q.isSelected = (q.id == selectedQuestionId);
            if (!filterQuestions.map(ques => ques.id).includes(q.id)) {
                filterQuestions.push(q);
            }
            return q;
        });
        return filterQuestions;
    }

    /**
     * Loads question view data.
     *
     */
    public onQuestionSelect(surveyQuestions: SurveyQuestionDeliverableView, filter: SurveyQuestionFilter, viewMetaInfo: SurveyQuestionsMetaInfo): void {
        this.surveyQuestionData = surveyQuestions;
        this.selectedQuestionText = surveyQuestions.questionText;
        this.selectedQuestionName = surveyQuestions.questionName;
        this.selectedQuestionTootip = this.translate.instant('select.survey.question.tooltip', {surveyQuestion: this.selectedQuestionName});
        this.filter = filter;
        this.viewSurveyQuestionsMetaInfo = viewMetaInfo;
        const [colHeaders, tableData] = this.surveyQuestionService.getTableData(this.filter, surveyQuestions, viewMetaInfo);
        this.dataSource = Object.keys(viewMetaInfo).length > 1 ? this.columnSortingOnSavedView(tableData, viewMetaInfo) : this.columnSorting(tableData, colHeaders);
        this.colHeaders = colHeaders;

        this.staticColumns = [0];
        this.columnWidths = this.getColumnWidths(colHeaders);
    }

    /**
     * Default sorting behavior if there is no view meta info
     * No Specific row is default sorted in Survey Questions
     * @param chartData
     * @param colHeaders
     */
    private columnSorting(chartData: any, colHeaders: any) {
        const dataSource = new MatTableDataSource(chartData);
        dataSource.sortingDataAccessor = this.matSortingDataAccessor;
        if (this.sort && this.sort.active != null) {
            this.sort.active = null;
        }
        if (this.sort) {
            dataSource.sort = this.sort;
        }
        return dataSource;
    }

    /**
     * Upon navigation to saved view sort the data based on existing view meta info
     * @param chartData
     * @param viewMetaInfo
     */
    private columnSortingOnSavedView(
        chartData: any,
        viewMetaInfo: SurveyQuestionsMetaInfo
    ) {
        const dataSource = new MatTableDataSource(chartData);
        dataSource.sortingDataAccessor = this.matSortingDataAccessor;
        if (Object.keys(viewMetaInfo).length > 1 && viewMetaInfo.sortInfo && Object.keys(viewMetaInfo.sortInfo).length > 0) {
            const sortState: Sort = {
                active: viewMetaInfo.sortInfo.columnHeaderName,
                direction: viewMetaInfo.sortInfo.sortDirection,
            };
            if (this.sort) {
                this.sort.active = sortState.active;
                this.sort.direction = sortState.direction;
                this.sort.sortChange.emit(sortState);
                dataSource.sort = this.sort;
            }
        }
        return dataSource;
    }

    /**
     * Angular material sorting data accessor function for attributes.
     *
     * @param {*} data
     * @param {string} sortHeaderId
     * @returns {(string | number)}
     * @memberOf ChartDataService
     */
    public matSortingDataAccessor(data: any, sortHeaderId: string): string | number {
        const cellData = data[sortHeaderId];
        if (!cellData) {
            return 0;
        }
        return cellData.actualValue;
    }

    /**
     * Return an array of column widths for all columns in the table.
     *
     * @param {any[]} colHeaders The array of headers
     */
    private getColumnWidths(colHeaders: any[]): number[] {
        const minWidth = 110;
        const columnWidths = Array(colHeaders.length).fill(minWidth);
        columnWidths[0] = 345;
        return columnWidths;
    }

    /**
     * Returns unique id for the loop to be refreshed.
     *
     * @param {number} index the questions table column for loop index
     * @param {any} item the colHeader object
     */
    public trackItem(index: number, item: any): string {
        return `${index}-${item.id ? item.id : 0}`;
    }

    /**
     * Updates displayed columns.
     *
     * @param {number[]} visibleColumns array of visible column indexes.
     */
    public update(visibleColumns: number[]): void {
        const columns = [];
        this.colHeaders.forEach((item, index) => {
            if (visibleColumns.indexOf(index) >= 0) {
                columns.push(item.name);
            }
        });
        this.displayedColumns = columns;
    }

    /**
     * Triggers mixpanel event on clicking 'Next' button in table
     *
     * @param none
     */
    nextPage(): void {
        this.mixpanelService.track(
            MixpanelLabel.surveyQuestions,
            MixpanelEvent.nextConcept
        );
    }

    /**
     * Triggers mixpanel event on clicking 'Previous' button in table
     *
     * @param none
     */
    previousPage(): void {
        this.mixpanelService.track(
            MixpanelLabel.surveyQuestions,
            MixpanelEvent.previousConcept
        );
    }

    /**
     * Cleanup hook.
     *
     * @memberOf SurveyQuestionComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * Right click for row highlight.
     *
     * @memberOf SurveyQuestionComponent
     */
    onContextMenu(event: MouseEvent, row: any) {
        event.preventDefault();
        this.setUserViewRowHighlights(row, 'negative');
    }

    /**
     * On click for row highlight.
     *
     * @memberOf SurveyQuestionComponent
     */
    onClick(event: MouseEvent, row: any) {
        event.preventDefault();
        this.setUserViewRowHighlights(row, 'positive');
    }

    /**
     * Update row highlights information in view meta info store.
     *
     * @memberof AttributesComponent
     */
    setUserViewRowHighlights(row: any, color: string) {
        const deliverableType = DeliverableType.SURVEY_QUESTION.type;
        const viewInfo = {deliverableType, rowHighlights: [], sortInfo: {}};
        viewInfo.rowHighlights =
            Object.keys(this.viewSurveyQuestionsMetaInfo).length > 1
                ? Object.values(this.viewSurveyQuestionsMetaInfo.rowHighlights)
                : [];
        viewInfo.sortInfo =
            Object.keys(this.viewSurveyQuestionsMetaInfo).length > 1
                ? this.viewSurveyQuestionsMetaInfo.sortInfo
                : {};
        let initialLength, updatedLength;
        if (viewInfo.rowHighlights.length) {
            initialLength = viewInfo.rowHighlights.length;
            viewInfo.rowHighlights = viewInfo.rowHighlights.filter(
                (x) => x.rowId !== row.answerId
            );
            updatedLength = viewInfo.rowHighlights.length;
        }
        if (initialLength === updatedLength) {
            viewInfo.rowHighlights.push({
                rowId: row.answerId,
                color: color,
            });
            this.mixpanelService.track(
                MixpanelLabel.surveyQuestions,
                color == 'positive' ? MixpanelEvent.tableHighlightPositive : MixpanelEvent.tableHighlightNegative
            );
        }
        this.viewMetaInfoService.update(viewInfo, deliverableType);
    }

    public getAggregateClass(
        aggregateValue: number,
        currentCellValue: number
    ): string {
        return currentCellValue >= aggregateValue
            ? 'text-accent'
            : 'text-highlight-muted';
    }

    /**
     * on Click of Column headers for sorting
     * @param event
     * @param colHeaderId
     * @param colHeaderName
     */
    onClickHeader(event: MouseEvent, colHeaderId: any, colHeaderName: string) {
        event.preventDefault();
        this.mixpanelService.track(
            MixpanelLabel.surveyQuestions,
            MixpanelEvent.tableSort
        );
        this.setUserViewSorting(colHeaderId, colHeaderName);
    }

    /**
     * Updates the sorting info in the ViewMetaInfo store
     * @param colHeaderId
     * @param colHeaderName
     */
    setUserViewSorting(colHeaderId: any, colHeaderName: string) {
        const deliverableType = DeliverableType.SURVEY_QUESTION.type;
        const viewInfo = {deliverableType, rowHighlights: [], sortInfo: {}};
        viewInfo.sortInfo['columnHeaderId'] = colHeaderId;
        viewInfo.sortInfo['columnHeaderName'] = colHeaderName;
        viewInfo.sortInfo['sortDirection'] = this.sort.direction;
        viewInfo.rowHighlights =
            Object.keys(this.viewSurveyQuestionsMetaInfo).length > 1
                ? Object.values(this.viewSurveyQuestionsMetaInfo.rowHighlights)
                : [];
        this.viewMetaInfoService.update(viewInfo, deliverableType);
    }

    /**
     * download survey questions data.
     *
     */
    download(event): void {
        this.mixpanelService.track(
            MixpanelLabel.surveyQuestions,
            MixpanelEvent.exportAsExcel
        );
        event.preventDefault();
        this.displayProgressSpinner = true;
        this.surveyQuestionFileService.download();
        this.spinnerService
            .getSpinnerObs()
            .subscribe((loading) => (this.displayProgressSpinner = loading));
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        const deliverableView = this.deliverableViews.find(it => it.productViewId === this.surveyQuestionData.id);
        this.isInsightEnable = true;
        this.deliverableData = {
            deliverable: {
                deliverableViewId: deliverableView.id,
                filter: this.filter,
                metaInfo: this.viewSurveyQuestionsMetaInfo,
                insightHTML: this.deliverableInsightService.getInsightHTML(),
            },
        };
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG();
        this.spinnerService
            .getSpinnerObs()
            .subscribe((loading) => (this.displayProgressSpinner = loading));
        this.mixpanelService.track(
            MixpanelLabel.surveyQuestions,
            MixpanelEvent.exportAsPNG
        );
    }

    /**
     * check concept count
     * */
    isConceptSubgroupExists(filter: SurveyQuestionFilter): boolean {
        const conceptCount = filter.concepts.filter((it) => it.isSelected).length;
        const subgroupCount = filter.subgroups.filter((it) => it.isSelected).length;
        return (
            (conceptCount === 0 || subgroupCount === 0) && !filter.show.aggregate
        );
    }

    getSortOrder(headerName: string) {
        let sortOrder = this.translate.instant('sort.lowest.to.highest');
        if (this.sort && this.sort.active === headerName) {
            if (this.sort.direction === 'asc') {
                sortOrder = this.translate.instant('sort.highest.to.lowest');
            } else if (this.sort.direction === 'desc') {
                sortOrder = this.translate.instant('sort.reset.to.default.order');
            }
        }
        return sortOrder;
    }

    /**
     * AutoComplete Search implementation for Filtering Questions List.
     */

    search(value): void {
        this.searchValue = value;
        this.filteredQuestionsList.forEach(ques => ques.isShow = false);
        this.questionsList.filter(it => it.name.toLowerCase().indexOf(value.toLowerCase()) > -1).forEach(ques => this.filteredQuestionsList.find(fQues => fQues.id === ques.id).isShow = true);
    }

    searchClearHandler() {
        this.searchValue = '';
        this.filteredQuestionsList = this.questionsList;
        this.filteredQuestionsList.forEach(ques => ques.isShow = true);
    }

    public textWithEllipsis(input, limit): string {
        return (input && input.length > limit) ? `${input.substring(0, limit)}...` : input;
    }

    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }

}
