import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {combineLatest, Subscription} from 'rxjs';
import {FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {FactorsMetaInfo} from '@app/deliverables/factors/models/view-meta-info.model';
import {FactorsService} from '@app/deliverables/factors/services/factors.service';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {debounceTime} from 'rxjs/operators';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {ReportService} from '@platform/services/report.service';
import {DeliverableViewType} from '../models/deliverable-view-type.enum';
import {SubgroupMeansService} from '../services/subgroup-means.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'ns-subgroup-means',
    templateUrl: './subgroup-means.component.html',
    styleUrls: ['./subgroup-means.component.scss'],
})

export class SubgroupMeansComponent implements OnInit {

    @Output() hasDeliverableData: EventEmitter<any> = new EventEmitter<boolean>();

    /**
     * Array of subscriptions for cleanup.
     *
     * @private
     * @type {Array<Subscription>}
     * @memberof SubgroupMeansComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * Factors deliverable view data.
     *
     * @type {FactorsDeliverableView}
     * @member SubgroupMeansComponent
     */
    public factorsDeliverableView: FactorsDeliverableView;

    /**
     * Array of displayed columns keys.
     *
     * @type {Array<string>}
     * @member SubgroupMeansComponent
     */
    public displayedColumns: string[];

    /**
     * Datasource object for the angular material table.
     *
     * @member SubgroupMeansComponent
     */
    public dataSource: MatTableDataSource<any>;

    /**
     * Internal user check
     *
     * @type {Boolean}
     * @member SubgroupMeansComponent
     */
    public isInternalUser: Boolean;

    /**
     * All column headers for the FFS Subgroup means view
     *
     * @type {Array<any>}
     * @member SubgroupMeansComponent
     */
    public colHeaders: Array<any>;

    /**
     * Meta info for Factors
     *
     * @type {FactorsMetaInfo}
     * @member SubgroupMeansComponent
     */
    public viewFactorsMetaInfo: FactorsMetaInfo;

    /**
     * View child for angular material table sorting
     *
     * @type {MatSort}
     * @member SubgroupMeansComponent
     */
    @ViewChild(MatSort) sort: MatSort;

    /**
     * Is the Report having any Alcohol DataSet information
     *
     * @type {boolean}
     * @member SubgroupMeansComponent
     */
    public isAlcoholStudy: boolean = false;

    /**
     * Is the Report having any Cannabis DataSet information
     *
     * @type {boolean}
     * @member SubgroupMeansComponent
     */
    public isCannabisStudy: boolean = false;

     /**
     * Check if data exists in the chart data of dataSource
     */
     public hasData: boolean;

    /**
     * Creates an instance of Subgroup Mean view SubgroupMeansComponent and initialize
     * the component data.
     *
     * @constructor
     * @param {FactorsService} factorsService
     * @param {ViewMetaInfoService} viewMetaInfoService
     * @param {UserService} userService
     * @param {SubgroupMeansService} subgroupMeansService
     * @member SubgroupMeansComponent
     */
    constructor(
        private factorsService: FactorsService,
        private reportService: ReportService,
        private subgroupMeansService: SubgroupMeansService,
        private viewMetaInfoService: ViewMetaInfoService,
        private translate: TranslateService,
    ) {
        this.subscriptions = [];
        this.displayedColumns = [];
    }

    /**
     * Initialize the table component for FFS
     *
     * @member SubgroupMeansComponent
     */
    ngOnInit(): void {
        const deliverableType = DeliverableType.FACTORS.type;
        const filter$ = this.factorsService.getFactorsFilter();
        const factors$ = this.factorsService.getFactors();
        const report$ = this.reportService.get();
        const viewMetaInfo$ = this.viewMetaInfoService.get<FactorsMetaInfo>(deliverableType);
        const subscription = combineLatest([factors$, filter$, report$, viewMetaInfo$]).pipe(debounceTime(10))
            .subscribe(([factors, filters, report, viewMetaInfo]) => {
                if (filters.deliverableViewType === DeliverableViewType.SUBGROUP) {
                    filters = JSON.parse(JSON.stringify(filters));
                    filters.show.factorsOptions = filters.show.factorsOptions.filter(fo => this.factorsService.getFactorsConfiguration()[fo.id] != undefined && this.factorsService.getFactorsConfiguration()[fo.id]);
                    filters.show.factorsOptions = filters.show.factorsOptions.filter(options => !this.factorsService.getExcludedKmaFactors().includes(options.id.toString()));
                    report.projectType && report.projectType.toLowerCase() === 'alcohol' ? this.isAlcoholStudy = true : this.isAlcoholStudy = false;
                    report.projectType && report.projectType.toLowerCase() === 'cannabis' ? this.isCannabisStudy = true : this.isCannabisStudy = false;
                    this.viewFactorsMetaInfo = viewMetaInfo;
                    const [colHeaders, factorsDataConcepts] = this.subgroupMeansService.fetchRecordsForSubgroupMeansView(filters, factors, this.isAlcoholStudy, this.isCannabisStudy);
                    this.hasData = factorsDataConcepts.length > 0;
                    this.hasDeliverableData.emit(this.hasData);
                    this.dataSource = (Object.keys(viewMetaInfo).length > 1) ? this.columnSortingOnSavedView(factorsDataConcepts, viewMetaInfo) : this.columnSorting(factorsDataConcepts, colHeaders);
                    this.displayedColumns = Object.keys(this.dataSource.data.length > 0 && this.dataSource.data[0]);
                    this.colHeaders = colHeaders;
                    this.getColumnWidths(colHeaders);
                }
            });
        this.factorsService.visibleColumnsSubject.subscribe(columns => {
            const newColumns = [];
            this.colHeaders.forEach((item, index) => {
                if (columns.indexOf(index) >= 0) {
                    newColumns.push(item.name);
                }
            });
            this.displayedColumns = newColumns;
        });
        this.subscriptions.push(subscription);
    }

    /**
     * Default sorting behavior if there is no view meta info
     * No Specific row is default sorted in Survey Questions
     * @param chartData
     * @param colHeaders
     */
    private columnSorting(chartData: any, colHeaders: any) {
        const dataSource = new MatTableDataSource(chartData);
        dataSource.sortingDataAccessor = this.matSortingDataAccessor;
        if (this.sort && this.sort.active != null) {
            this.sort.active = null;
        }
        if (this.sort) {
            dataSource.sort = this.sort;
        }
        return dataSource;
    }

    /**
     * Upon navigation to saved view sort the data based on existing view meta info
     * @param chartData
     * @param viewMetaInfo
     */
    private columnSortingOnSavedView(chartData: any, viewMetaInfo: FactorsMetaInfo) {
        const dataSource = new MatTableDataSource(chartData);
        dataSource.sortingDataAccessor = this.matSortingDataAccessor;
        if (Object.keys(viewMetaInfo).length > 1 && viewMetaInfo.sortInfo && Object.keys(viewMetaInfo.sortInfo).length > 0) {
            const sortState: Sort = {active: viewMetaInfo.sortInfo.columnHeaderName, direction: viewMetaInfo.sortInfo.sortDirection};
            this.sort.active = sortState.active;
            this.sort.direction = sortState.direction;
            this.sort.sortChange.emit(sortState);
            dataSource.sort = this.sort;
        }
        return dataSource;
    }

    /**
     * Angular material sorting data accessor function for attributes.
     *
     * @param {*} data
     * @param {string} sortHeaderId
     * @returns {(string | number)}
     * @member SubgroupMeansComponent
     */
    public matSortingDataAccessor(data: any, sortHeaderId: string): string | number {
        const cellData = data[sortHeaderId];
        if (!cellData) {
            return 0;
        }
        return cellData.mean;
    }


    /**
     * Return an array of column widths for all columns in the table.
     *
     * @param {any[]} colHeaders The array of headers
     */
    private getColumnWidths(colHeaders: any[]) {
        const minWidth = 70;
        const columnWidths = Array(colHeaders.length).fill(minWidth);
        columnWidths[0] = 345;
        this.subgroupMeansService.columnWidthsSubject.next(columnWidths);
    }

    /**
     * on Click of Column headers for sorting
     * @param event
     * @param colHeaderId
     * @param colHeaderName
     */
    onClickHeader(event: MouseEvent, colHeaderId: any, colHeaderName: string) {
        event && event.preventDefault();
        this.setUserViewSorting(colHeaderId, colHeaderName);
    }

    /**
     * Updates the sorting info in the ViewMetaInfo store
     * @param colHeaderId
     * @param colHeaderName
     */
    setUserViewSorting(colHeaderId: any, colHeaderName: string) {
        const deliverableType = DeliverableType.FACTORS.type;
        const viewInfo = {deliverableType, sortInfo: {}};
        viewInfo.sortInfo['columnHeaderId'] = colHeaderId;
        viewInfo.sortInfo['columnHeaderName'] = colHeaderName;
        viewInfo.sortInfo['sortDirection'] = this.sort.direction;
        this.viewMetaInfoService.update(viewInfo, deliverableType);
    }

    getSortOrder(headerName: string) {
        let sortOrder = this.translate.instant('sort.lowest.to.highest');
        if (this.sort && this.sort.active === headerName) {
            if (this.sort.direction === 'asc') {
                sortOrder = this.translate.instant('sort.highest.to.lowest');
            } else if (this.sort.direction === 'desc') {
                sortOrder = this.translate.instant('sort.reset.to.default.order');
            }
        }
        return sortOrder;
    }

    /**
     * Returns unique id for the loop to be refreshed.
     *
     * @param {number} index the attribute table column for loop index
     * @param {any} item the colHeader object
     */
    public trackItem(index: number, item: any): string {
        return `${index}-${item.id ? item.id : 0}`;
    }

}
