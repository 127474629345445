import { DeliverableType } from '@products/quick-screen/deliverable-type.enum';
import { map, skipWhile, take, takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {AxisData, Concept, SortingMatrixDeliverable} from '@products/quick-screen/sorting-matrix/models/sorting-matrix.model';
import {SortingMatrixService} from '@products/quick-screen/sorting-matrix/sorting-matrix.service';
import {combineLatest, forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {ExportPngService} from '@platform/services/export-png.service';
import {ReportService} from '@platform/services/report.service';
import {UserService} from '@platform/services/user.service';
import {SortingMatrixFilter} from '@products/quick-screen/sorting-matrix/models/filter.model';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {DeliverableInfo} from '@platform/models/deliverable-info.model';
import {UserView} from '@platform/models/user-view.model';
import {Report} from '@platform/models/report.model';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {SortingMatrixMetaInfo} from '@products/quick-screen/sorting-matrix/models/view-meta-info.model';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {SpinnerService} from '@platform/services/spinner.service';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent} from '@src/assets/utils/mixpanel-enum';
import { RouterService } from '@platform/services/router.service';
import { UserViewService } from '@platform/services/user-view.service';
import { FilterService } from '@platform/services/filter.service';
import { DeliverableInfoService } from '@platform/services/deliverable-info.service';
import { InsightService } from '@platform/insights/insights.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';

@Component({
  selector: 'qs-sorting-matrix',
  templateUrl: './sorting-matrix.component.html',
  styleUrls: ['./sorting-matrix.component.scss'],
  providers: [SortingMatrixService]
})
export class SortingMatrixComponent implements OnInit, OnDestroy {

  public sortingMatrix$: Observable<SortingMatrixDeliverable>;
  public concepts$: Observable<Concept[]>;
  public selectedConcept: Concept;
  public axisData: AxisData;

  /**
   * Sorting matrix deliverable data
   * @type {SortingMatrixDeliverable} data
   * @memberOf SortingMatrixComponent
   */
  public data: SortingMatrixDeliverable;

  /**
   * Sorting matrix filter data
   * @type {SortingMatrixFilter} sortingMatrixFilter
   * @memberOf SortingMatrixComponent
   */
  public filter: SortingMatrixFilter;

  public viewMetaInfo: SortingMatrixMetaInfo;

  public filterStatus: any;

  /**
   * The deliverable insight data when creating insight.
   * @type {DeliverableInsight} deliverableData
   * @memberOf SortingMatrixComponent
   */
  public deliverableInsight: DeliverableInsight;

  public isInsightEnable = false;
  public displayProgressSpinner = false;

  /**
   * If the current user is internal
   */
  public isInternalUser: Boolean;

  private subscriptions: Array<Subscription>;

  public disableBtn: boolean;

    /**
     * Report document
     */
    public report: Report;

    /**
     * The deliverable view object.
     */
    public deliverableViewType: string;

    public selectors: any;

    public deliverableInfos: Array<DeliverableInfo>;

    public deliverableViews: Array<DeliverableView>;

    public deliverableType = DeliverableType.SORTING_MATRIX.type;

    public userViews: Array<UserView>;

    private destroy$ = new Subject<void>();

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

  /**
   * Sorting Matrix constructor
   * @param service
   * @param reportService
   * @param userService
   * @param exportPNGService
   * @param viewMetaInfoService
   * @param deliverableInsightService
   */
  constructor(public service: SortingMatrixService,
              public reportService: ReportService,
              private userService: UserService,
              private spinnerService: SpinnerService,
              private exportPNGService: ExportPngService,
              private viewMetaInfoService: ViewMetaInfoService,
              private deliverableInsightService: DeliverableInsightService,
              private mixpanelService: MixpanelService,
              private routeService: RouterService,
              private userViewService: UserViewService,
              private filterService: FilterService,
              private deliverableInfoService: DeliverableInfoService,
              private insightService: InsightService) {
      this.subscriptions = [];
      this.userViews = [];
  }

  /**
   * Initialize component
   */
  ngOnInit() {
      const insightId = this.routeService.getQueryParam('insightId');
      this.sortingMatrix$ = this.service.getFilteredDataWithConceptImage();
      const filter$ = this.service.getSortingMatrixFilter();
      const deliverableType = DeliverableType.SORTING_MATRIX.type;
      const viewMetaInfo$ = this.viewMetaInfoService.get<SortingMatrixMetaInfo>(deliverableType);

      const subscription = combineLatest([
          this.reportService.get(),
          this.userService.getUser(),
      ]).subscribe(([report, user]) => {
          this.report = report;
          this.deliverableInfos = this.deliverableInfoService.getNonForecastDeliverables(report);
          this.isInternalUser = user.isInternalUser;
          this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');

          const userViews$ = this.userViewService.fetchReportUserViewsFromAPI(this.report.id);
          const insightFilter$ = this.insightService.getInsightFilterData<SortingMatrixFilter>(report.id, insightId);
          const defaultFilter$ = this.service.loadDefaultFilter().pipe(
              take(1), takeUntil(this.destroy$)
          );
          forkJoin([userViews$, insightFilter$, defaultFilter$])
              .subscribe(([userViews, insightFilter, defaultFilter]) => {
              this.userViews = this.userViewService.setupUserViews(this.report.id, this.deliverableType, userViews, defaultFilter, insightFilter);
              const insightView = this.userViews.find(it => it.id === this.userViewService.insightViewId);
              this.selectUserView(insightView ? insightView : this.userViews.find(it => it.isSelected));

              /**
               * Add subscription to watch filter changes here so that deliverable data can also be updated as per filter change.
               * */
              this.subscriptions.push(combineLatest([this.sortingMatrix$, filter$, viewMetaInfo$])
                  .pipe(skipWhile(([sortingMatrix, filter, viewMetaInfo]) => viewMetaInfo == null))
                  .subscribe(([sortingMatrix, filter, viewMetaInfo]) => {
                      this.data = sortingMatrix;
                      this.axisData = sortingMatrix.axisData;
                      this.filter = filter;
                      this.viewMetaInfo = viewMetaInfo;
                      if (this.isInsightEnable && this.isAutomatedHeadlinesEnabled) {
                          this.openInsightCreationForm();
                      }
                  }));
          });
      });
      this.concepts$ = this.sortingMatrix$.pipe(
          map(sortingMatrix => sortingMatrix.concepts)
      );
      this.subscriptions.push(subscription);
  }



    /**
   * event emitted by the child components to set highlighted concept.
   */
  highlightConcept(concept: Concept) {
    this.selectedConcept = concept;
  }

  /**
   * reset form
   */
  getFilterStatus(status: any) {
    this.filterStatus = status;
  }

  /**
   * toggle between headers and insight creation form.
   */
  openInsightCreationForm() {
    this.isInsightEnable = true;
    const insightHTMLData = this.deliverableInsightService.getInsightHTML(this.updateNode);
    this.deliverableInsight = {
      selectors: ['#sorting-matrix-chart-1'],
      deliverable: {
        deliverableViewId: this.data.id,
        filter: this.filter,
        metaInfo: this.viewMetaInfo,
        insightHTML: insightHTMLData
      }
    };
  }

  /*
  * updates insightHTML node by adding <style>
  */
  updateNode(node) {
    // changing the style of SM chart to fix chart not rendering completely - S2-914
    node.getElementsByClassName('pr-10')[0].classList.add('p-0');
    // appending style tag as node's firstChildElement - to fix style issues of sorting matrix & attributes DOM - S2-910
    node.insertAdjacentHTML('afterbegin', `<style>.mat-sort-header-container{display:flex;cursor:pointer;align-items:center}.mat-sort-header-disabled .mat-sort-header-container{cursor:default}.mat-sort-header-position-before{flex-direction:row-reverse}.mat-sort-header-button{border:none;background:0 0;display:flex;align-items:center;padding:0;cursor:inherit;outline:0;font:inherit;color:currentColor;position:relative}[mat-sort-header].cdk-keyboard-focused .mat-sort-header-button,[mat-sort-header].cdk-program-focused .mat-sort-header-button{border-bottom:solid 1px currentColor}.mat-sort-header-button::-moz-focus-inner{border:0}.mat-sort-header-arrow{height:12px;width:12px;min-width:12px;position:relative;display:flex;opacity:0}.mat-sort-header-arrow,[dir=rtl] .mat-sort-header-position-before .mat-sort-header-arrow{margin:0 0 0 6px}.mat-sort-header-position-before .mat-sort-header-arrow,[dir=rtl] .mat-sort-header-arrow{margin:0 6px 0 0}.mat-sort-header-stem{background:currentColor;height:10px;width:2px;margin:auto;display:flex;align-items:center}.cdk-high-contrast-active .mat-sort-header-stem{width:0;border-left:solid 2px}.mat-sort-header-indicator{width:100%;height:2px;display:flex;align-items:center;position:absolute;top:0;left:0}.mat-sort-header-pointer-middle{margin:auto;height:2px;width:2px;background:currentColor;transform:rotate(45deg)}.cdk-high-contrast-active .mat-sort-header-pointer-middle{width:0;height:0;border-top:solid 2px;border-left:solid 2px}.mat-sort-header-pointer-left,.mat-sort-header-pointer-right{background:currentColor;width:6px;height:2px;position:absolute;top:0}.cdk-high-contrast-active .mat-sort-header-pointer-left,.cdk-high-contrast-active .mat-sort-header-pointer-right{width:0;height:0;border-left:solid 6px;border-top:solid 2px}.mat-sort-header-pointer-left{transform-origin:right;left:0}.mat-sort-header-pointer-right{transform-origin:left;right:0}
    </style>`);
  }

  /**
   * Close insight form
   */
  closeInsight() {
    this.isInsightEnable = false;
  }

  /**
   * capture screen layout and export as png.
   */
  exportAsPNG() {
    this.displayProgressSpinner = true;
    this.exportPNGService.exportPNG();
    const subscription = this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
    this.subscriptions.push(subscription);
    this.mixpanelService.track(MixpanelLabel.sortingMatrix, MixpanelEvent.exportAsPNG);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
  * check concept count
  */
  isConceptExists(filter: SortingMatrixFilter): boolean {
    const conceptCount = filter.concepts.filter(it => it.selected === true).length;
    return (conceptCount === 0);
  }

    /**
     * Method that is triggered when user view is changed. This will in turn update the filter model in the store.
     * */
    selectUserView(userView: UserView): void {
        this.filter = userView.filter as SortingMatrixFilter;
        this.disableBtn = this.isConceptExists(this.filter);
        this.filterService.update(userView.filter);
    }


    /**
     * Action that is triggered when the deliverable info is changed.
     *
     * @param deliverableInfo
     */
    onDeliverableChange(deliverableInfo: DeliverableInfo): void {
        this.deliverableInfoService.routeToDeliverable(deliverableInfo);
    }

}
