import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DeliverableInsight } from '@platform/deliverable-insight/deliverable-insight.model';
import { DeliverableInsightService } from '@platform/services/deliverable-insight.service';
import { PerformanceService } from './services/performance.service';
import { UserService } from '@platform/services/user.service';
import { SpinnerService } from '@platform/services/spinner.service';
import { ExportPngService } from '@platform/services/export-png.service';
import { ViewMetaInfoService } from '@platform/services/view-meta-info.service';
import { combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PerformanceDeliverableView } from './models/performance.model';
import { PerformanceFilter } from './models/filter.model';
import { Subscription } from 'rxjs';
import { PerformanceChartDataService } from './services/performance-chart-data.service';
import { PerformanceMetaInfo } from './models/performance-view-meta-info.model';
import { DeliverableType } from '../deliverable-type.enum';
import { MixpanelService } from '@platform/services/mixpanel.service';
import { MixpanelLabel, MixpanelEvent } from '@src/assets/utils/mixpanel-enum';

/**
 * `<ns-performance>` component builds performance deliverable for both
 * concepts and subgroups deliverable views.
 *
 * @example
 * <ns-performance></ns-performance>
 *
 * @export
 * @class PerformanceComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ns-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss'],
})
export class PerformanceComponent implements OnInit, OnDestroy {

  /**
   * Performance deliverable view data.
   *
   * @type {PerformanceDeliverableView}
   * @member PerformanceComponent
   */
  public performanceDeliverableView: PerformanceDeliverableView;

  /**
   * Performance deliverable view filter object.
   *
   * @type {PerformanceFilter}
   * @member PerformanceComponent
   */
  public filter: PerformanceFilter;

  /**
   * Subscription objects for cleanup.
   *
   * @type {Array<Subscription>}
   * @member PerformanceComponent
   */
  public subscriptions: Array<Subscription>;

  /**
   * Datasource object for the angular material table.
   *
   * @member PerformanceComponent
   */
  public dataSource: MatTableDataSource<any>;

  /**
   * Array of displayed columns keys.
   *
   * @type {Array<string>}
   * @member PerformanceComponent
   */
  public displayedColumns: Array<string>;

  /**
   * toggle insight btn
   * @type {Boolean} isInsightEnable
   * @member PerformanceComponent
   */
  public isInsightEnable = false;

  /**
   * The deliverable insight data when creating insight.
   * @type {DeliverableInsight} deliverableData
   * @member PerformanceComponent
   */
  public deliverableData: DeliverableInsight;

  /**
   * All column headers for the performance table.
   *
   * @type {Array<any>}
   * @member PerformanceComponent
   */
  public colHeaders: Array<any>;

  /**
   * Spinner.
   *
   * @type {Boolean}
   * @member PerformanceComponent
   */
  public displayProgressSpinner = false;

  /**
   * Disable Insights Button.
   *
   * @type {Boolean}
   * @member PerformanceComponent
   */
  public disableBtn: boolean;

  /**
   * Meta info for Performance
   *
   * @type {PerformanceDeliverableView}
   * @memberOf PerformanceComponent
   */
  public viewPerformanceMetaInfo: PerformanceMetaInfo;

  /**
   * Internal User
   *
   * @type {Boolean}
   * @member PerformanceComponent
   */
  public isInternalUser: Boolean;

  /**
   * Tooltip text
   *
   * @type {String}
   * @member PerformanceComponent
   */
  public relaunchScoreTooltip: String = 'Relaunch Potential Score indicates the overall volume potential of the renovation indexed to the current, incorporating discrete choice preference, claimed purchase interest, frequency, units & value perceptions.';

  /**
   * Display bar chart.
   *
   * @type {Boolean}
   * @member InteractionsComponent
   */
   public displayBarchart: Boolean = false;


  /**
  * View children for angular material table header cell.
  *
  * @type {MatSort}
  * @member InteractionsComponent
  */
   @ViewChildren('columnHeader') columnHeaders: QueryList<any>;

   /**
    * Feature FLAG for Automatic Headlines.
    *
    * @type {Boolean}
    */
   public isAutomatedHeadlinesEnabled: boolean;

  /**
   * Creates an instance of PerformanceComponent and initialize
   * the component data.
   *
   * @constructor
   * @param {PerformanceService} performanceService
   * @param exportPNGService
   * @param spinnerService
   * @param deliverableInsightService
   * @param userService
   * @param performanceChartDataService
   * @param ViewMetaInfoService
   * @param  {MixpanelService} mixpanelService
   * @member PerformanceComponent
   */

  constructor(
    private deliverableInsightService: DeliverableInsightService,
    private performanceService: PerformanceService,
    private exportPNGService: ExportPngService,
    private spinnerService: SpinnerService,
    private viewMetaInfoService: ViewMetaInfoService,
    private userService: UserService,
    private performanceChartDataService: PerformanceChartDataService,
    private cdr: ChangeDetectorRef,
    private mixpanelService: MixpanelService
  ) {
    this.displayedColumns = [];
    this.subscriptions = [];
  }

  ngOnInit(): void {
    const user$ = this.userService.getUser();
    const filter$ = this.performanceService.getPerformanceFilter();
    const performance$ = this.performanceService.getPerformance();
    const deliverableType = DeliverableType.PERFORMANCE.type;
    const viewMetaInfo$ = this.viewMetaInfoService.get<PerformanceMetaInfo>(deliverableType);
    const subscription = combineLatest([performance$, filter$, viewMetaInfo$, user$])
      .pipe(debounceTime(10))
      .subscribe(([performance, filters, viewMetaInfo, user]) => {
        this.isInternalUser = user.isInternalUser;
        this.performanceDeliverableView = performance;
        this.filter = filters;
        this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
        this.viewPerformanceMetaInfo = viewMetaInfo;
        const [colHeaders, chartData] = this.performanceChartDataService.getChartData(filters, performance);
        this.dataSource = new MatTableDataSource(chartData);
        this.displayedColumns = colHeaders.map((item) => item.name);
        this.colHeaders = colHeaders;
        this.modifyBarChartData(chartData);
        this.cdr.detectChanges();
        if (this.isInsightEnable && this.isAutomatedHeadlinesEnabled) {
              this.openInsightCreationForm();
        }
      });
    this.subscriptions.push(subscription);
  }

  /**
   * modifies bar chart data
   * @param chartData
   */
   private modifyBarChartData(chartData: any) {
    if (this.columnHeaders) {
      const subscription = this.columnHeaders.changes.subscribe(header => {
        this.dataSource.data = this.setBarChartWidth(chartData);
        this.cdr.detectChanges();
        this.displayBarchart = true;
      });
      if (this.columnHeaders.first) {
        this.dataSource.data = this.setBarChartWidth(chartData);
      }
      this.displayBarchart = true;
      this.subscriptions.push(subscription);
    }
  }

  /**
   * sets the bar chart width and max range from the column header's cell width
   * @param chartData
   */
   private setBarChartWidth(chartData: any) {
    this.displayBarchart = false;
    const modifiedChartData = chartData.map(data => {
      for (const key in data) {
        if (this.columnHeaders.first && key !== 'subgroup') {
          data[key].barChart.options.width = this.columnHeaders.first.nativeElement.offsetWidth - 20;
          data[key].barChart.options.bar.range.max = this.columnHeaders.first.nativeElement.offsetWidth - 40;
        }
      }
      return data;
    });
    return modifiedChartData;
  }

  /**
   * Returns unique id for the loop to be refreshed.
   *
   * @param {number} index the performance table column for loop index
   * @param {any} item the colHeader object
   */
  public trackItem(index: number, item: any): string {
    return `${index}-${item.id ? item.id : 0}`;
  }

  /**
   * Set Views
   * @param evt { String }
   */
  setViews(evt: any) {
    /**
     * ToDo:
     * Create a data model for views which has all details (sorting, highlights, filters, Insights ... )
     * Store the view in the DB
     * Create a Default View (set to defaults)
     * Set the view data on load
     */
    console.log(evt);
  }

  /**
   * capture screen layout and export as png.
   *
   */
  exportAsPNG() {
    this.mixpanelService.track(MixpanelLabel.performance, MixpanelEvent.exportAsPNG);
    this.displayProgressSpinner = true;
    this.exportPNGService.exportPNG();
    this.spinnerService.getSpinnerObs().subscribe((loading) => this.displayProgressSpinner = loading);
  }

  /**
   * toggle between headers and insight creation form.
   *
   */
  openInsightCreationForm() {
    this.isInsightEnable = true;
    const insightHTMLData = this.deliverableInsightService.getInsightHTML();
    this.deliverableData = {
      deliverable: {
        deliverableViewId: this.performanceDeliverableView.id,
        filter: this.filter,
        metaInfo: this.viewPerformanceMetaInfo,
        insightHTML: insightHTMLData,
      },
    };
  }

  /**
   * Close insight form
   */
  closeInsight() {
    this.isInsightEnable = false;
  }

  /**
   * Cleanup hook.
   *
   * @member PerformanceComponent
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
