<div id="factors-for-success-legend" class="footer">
  <div class="footer-text" *ngIf="isConceptView">
    <div class="section-content">
      <div *ngIf="factorsConfiguration?.distinctProposition" class="legend">
        <strong>{{'quick.predict.deliverables.factors.legend.footnote.dp' | translate }}</strong>
        <span>{{'quick.predict.deliverables.factors.label.distinct.proposition' | translate }}</span>
      </div>
      <div *ngIf="factorsConfiguration?.attentionCatching" class="legend">
        <strong>{{'quick.predict.deliverables.factors.legend.footnote.ac' | translate }}
        </strong><span>{{'quick.predict.deliverables.factors.label.attention.catching' | translate }}</span>
      </div>
      <div *ngIf="factorsConfiguration?.messageConnection" class="legend">
        <strong>{{'quick.predict.deliverables.factors.legend.footnote.mc' | translate
          }}</strong><span>{{'quick.predict.deliverables.factors.label.message.connection' | translate }}</span>
      </div>
      <div *ngIf="factorsConfiguration?.clearConciseMessage" class="legend">
        <strong>{{'quick.predict.deliverables.factors.legend.footnote.cm' | translate
          }}</strong><span>{{'quick.predict.deliverables.factors.label.clear.concise.message' | translate }}</span>
      </div>
      <span *ngIf="isAlcoholStudy || isCannabisStudy; else elseBlock">
        <div *ngIf="factorsConfiguration?.desire" class="legend"><strong>{{'quick.predict.deliverables.factors.legend.footnote.d' | translate
            }}</strong><span>{{'quick.predict.deliverables.factors.label.desire' | translate }}</span>
        </div>
      </span>
      <ng-template #elseBlock>
        <div *ngIf="factorsConfiguration?.needDesire" class="legend">
          <strong>{{'quick.predict.deliverables.factors.legend.footnote.nd' | translate
            }}</strong><span>{{'quick.predict.deliverables.factors.label.need.desire' | translate }}</span>
        </div>
      </ng-template>
      <div *ngIf="factorsConfiguration?.advantage" class="legend">
        <strong>{{'quick.predict.deliverables.factors.legend.footnote.ad' | translate
          }}</strong><span>{{'quick.predict.deliverables.factors.label.advantage' | translate }}</span>
      </div>
      <div *ngIf="factorsConfiguration?.credibility" class="legend">
        <strong>{{'quick.predict.deliverables.factors.legend.footnote.cr' | translate
          }}</strong><span>{{'quick.predict.deliverables.factors.label.credibility' | translate }}</span>
      </div>
      <div *ngIf="factorsConfiguration?.acceptableCosts" class="legend">
        <strong>{{'quick.predict.deliverables.factors.legend.footnote.as' | translate
          }}</strong><span>{{'quick.predict.deliverables.factors.label.acceptable.costs' | translate }}</span>
      </div>
    </div>
    <div id="factors-footnote" class="section footer-legend-text">
      <small>{{ 'quick.predict.deliverables.factors.legend.footnote.comparisons' | translate }}
        <ol *ngIf="primaryCompetitiveText.length > 0">
          <li *ngFor="let text of primaryCompetitiveText">{{text}}</li>
        </ol>
      </small>
    </div>
  </div>
  <div class="section-content" *ngIf="!isConceptView">
    <div class="legend">
      <span class="rounded-square outstanding"></span>
      <span class="space-left-sm">{{'quick.predict.deliverables.factors.filter.thresholds.outstanding' | translate
        }}</span>
    </div>
    <div class="legend">
      <span class="rounded-square ready"></span>
      <span class="space-left-sm">{{'quick.predict.deliverables.factors.filter.thresholds.ready' | translate }}</span>
    </div>
    <div class="legend">
      <span class="rounded-square risky"></span>
      <span class="space-left-sm">{{'quick.predict.deliverables.factors.filter.thresholds.risky' | translate }}</span>
    </div>
    <div class="legend">
      <span class="rounded-square high-risk"></span>
      <span class="space-left-sm">{{'quick.predict.deliverables.factors.filter.thresholds.high.risk' | translate
        }}</span>
    </div>
    <div id="footnote" class="section footer-legend-text">
      <p *ngIf="factorsConfiguration?.distinctProposition">{{'quick.predict.deliverables.factors.legend.footnote.distinct.proposition' | translate }}</p>
      <p *ngIf="factorsConfiguration?.attentionCatching">{{'quick.predict.deliverables.factors.legend.footnote.attention.catching' | translate }}</p>
      <p *ngIf="(factorsConfiguration?.messageConnection || factorsConfiguration?.clearConciseMessage)">{{'quick.predict.deliverables.factors.legend.footnote.message.connection' | translate }}</p>
      <p *ngIf="filter.show.statTesting">{{'quick.predict.deliverables.factors.legend.footnote.stat.testing' | translate }}</p>
    </div>
  </div>
</div>
