import {Concept} from '@platform/models/concept.model';
import {FactorsFilter, FilterItem} from '@app/deliverables/factors/models/filter.model';
import {FactorsService} from '@app/deliverables/factors/services/factors.service';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {FilterService} from '@platform/services/filter.service';
import {ReportService} from '@platform/services/report.service';
import {TranslateService} from '@ngx-translate/core';
import {Report} from '@platform/models/report.model';
import {DataType, DropdownData, DropdownItem} from '@products/shared/dropdown/dropdown.data.model';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableViewType} from '@app/deliverables/factors/models/deliverable-view-type.enum';
import {FactorsDeliverableView} from '@app/deliverables/factors/models/factors.model';
import {MixpanelService} from '@platform/services/mixpanel.service';
import {MixpanelEvent, MixpanelLabel} from '@src/assets/utils/mixpanel-enum';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';

/**
 * `FilterComponent` creates the Factors For Success filter options
 * like compare, concepts, country, subgroups, and show filter.
 *
 * @example
 *   <ns-factors-filter></ns-factors-filter>
 *
 * @export
 * @class FilterComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'ns-factors-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

    @Input() public concept: Concept;
    @Input() public isConceptView: Boolean;

    /**
     * Factors For Success filter object.
     *
     * @property
     * @type {FactorsFilter}
     * @memberof FilterComponent
     */
    public filter: FactorsFilter;

    /**
     * List of concept dropdown items.
     *
     * @property
     * @type {Array<DropdownData>}
     * @memberof FilterComponent
     */
    public conceptsListData: DropdownData<string>;

    /**
     * List of subgroup dropdown items.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public subgroupListData: DropdownData<string>;

    /**
     * List of deliverable view dropdown items.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public compareListData: DropdownData<string>;

    /**
     * List of countries on the report.
     *
     * @property
     * @type {DropdownData}
     * @memberof FilterComponent
     */
    public countryListData: DropdownData<string>;

    /**
     * Array of subscriptions for cleanup.
     *
     * @property
     * @private
     * @type {Array<Subscription>}
     * @memberof FilterComponent
     */
    private subscriptions: Array<Subscription>;

    /**
     * Is the Report having any Alcohol DataSet information
     *
     * @type {boolean}
     * @memberof FilterComponent
     */
    public isAlcoholStudy: boolean;

    /**
     * Is the Report having any Cannabis DataSet information
     *
     * @type {boolean}
     * @memberof FilterComponent
     */
    public isCannabisStudy: boolean;

    /**
     * Factors deliverable view data.
     *
     * @type {FactorsDeliverableView}
     * @memberof FilterComponent
     */
    public factorsDeliverableView: FactorsDeliverableView;
    /**
     * Mixpanel label for the deliverable
     *
     * @property
     * @private
     * @type {string}
     * @memberof FilterComponent
     */
    private deliverableLabel: string;

    /**
     * Creates an instance of FilterComponent.
     *
     * @constructor
     * @param {FactorsService} FactorsService
     * @param {FilterService} filterService
     * @param {ReportService} reportService
     * @param {TranslateService} translate
     * @param {MixpanelService} mixpanelService
     *
     * @memberof FilterComponent
     */
    constructor(
        private factorsService: FactorsService,
        private filterService: FilterService,
        private reportService: ReportService,
        private translate: TranslateService,
        private mixpanelService: MixpanelService,
        private deliverableViewService: DeliverableViewService
    ) {
        this.subscriptions = [];
    }

    /**
     * Initialize the filter component. Sets filter and report object
     * for the template.
     *
     * @memberof FilterComponent
     */
    ngOnInit(): void {
        this.deliverableLabel = this.concept ? MixpanelLabel.concepts : MixpanelLabel.factors;
        if (this.isConceptView) {
            this.factorsService.loadDefaultFilter(this.concept);
        }
        const filter$ = this.factorsService.getFactorsFilter();
        const report$ = this.reportService.get();
        const factors$ = this.factorsService.getFactors();
        const subscription = combineLatest([filter$, report$, factors$]).subscribe(([filters, report, factors]) => {
            this.filter = filters;
            this.factorsDeliverableView = factors;
            report.projectType && report.projectType.toLowerCase() === 'alcohol' ? this.isAlcoholStudy = true : this.isAlcoholStudy = false;
            report.projectType && report.projectType.toLowerCase() === 'cannabis' ? this.isCannabisStudy = true : this.isCannabisStudy = false;
            this.getCompareOptions(this.filter);
            this.setFilters(this.filter, report);
        });
        this.subscriptions.push(subscription);
    }

    /**
     * Event listener for concept selection change event.
     *
     * @listens event:selectionChange
     * @memberof FilterComponent
     * @param allConcepts
     */
    selectConcept(allConcepts: Array<DropdownItem<string>>) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        this.filterService.update({
            ...this.filter,
            concepts: this.filter.concepts.map((concept) => {
                return {
                    ...concept,
                    isSelected: allConcepts.find(selectedConcept => selectedConcept.value === concept.name).selected
                };
            })
        });
    }

    /**
     * Event listener for subgroup selection change event.
     *
     * @listens event:selectionChange
     * @param {Array<DropdownItem>} subgroups
     * @memberof FilterComponent
     */
    selectSubgroup(subgroups: Array<DropdownItem<string>>) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        const newFilterData = {
            ...this.filter,
            subgroups: this.filter.subgroups.map((subgroup) => {
                return {
                    ...subgroup,
                    isSelected: subgroups.find(selectedSubgroup => selectedSubgroup.value === subgroup.name).selected
                };
            })
        };
        this.filterService.update(newFilterData);
    }

    /**
     * Sets the filter options for concepts, subgroups, compareList, countries.
     *
     * @private
     * @param {FactorsFilter} filter
     * @param {Report} report
     * @memberof FilterComponent
     */
    private setFilters(filter: FactorsFilter, report: Report): void {
        this.conceptsListData = this.getConceptFilterOptions(filter);
        this.subgroupListData = this.getSubGroupFilterOptions(filter);
        this.countryListData = this.getCountryOptions(filter.countries[0]);
    }

    /**
     * Sets the compare filter options.
     *
     * @private
     * @memberof FilterComponent
     */
    private getCompareOptions(filter: FactorsFilter) {
        const viewType: string = filter.deliverableViewType;
        const deliverableType: string = DeliverableType.FACTORS.type;
        const deliverableViews = this.deliverableViewService.getDeliverableViews(deliverableType).subscribe((compareDeliverableViewArray) => {
            const compareListItemData: Array<DropdownItem<string>> = [];
            const factorsDeliverable: DeliverableView = compareDeliverableViewArray.find(deliverable => deliverable.type === deliverableType);
            filter.compare.forEach((option: FilterItem) => {
                const filterId: string = option.id.toString();
                const factorsOption: FilterItem = JSON.parse(JSON.stringify(option));
                if (filterId === DeliverableViewType.SUBGROUP) {
                    factorsOption.name = this.translate.instant('quick.predict.deliverables.factors.filter.subgroups.compare.label');
                }
                // if view exists for this filter option add it to list
                if (factorsDeliverable) {
                    compareListItemData.push(
                        {
                            value: filterId,
                            label: factorsOption.name,
                            selected: factorsOption.id === viewType
                        }
                    );
                }
                this.compareListData = {
                    dropdownLabel: this.translate.instant('quick.predict.deliverables.factors.filter.compare.label'),
                    dataType: DataType.RADIO,
                    contentTitle: this.translate.instant('quick.predict.deliverables.factors.filter.compare.itemsTitle'),
                    data: compareListItemData
                };
            });
        });
    }

    /**
     * Returns the country filter options.
     *
     * @private
     * @param {string} country
     * @returns {DropdownData}
     * @memberof FilterComponent
     */
    private getCountryOptions(country: string): DropdownData<string> {
        return {
            dropdownLabel: this.translate.instant('quick.predict.deliverables.factors.filter.country.label') + ` : ` + this.translate.instant('quick.predict.deliverables.factors.filter.country.label'),
            dataType: DataType.RADIO,
            contentTitle: this.translate.instant('quick.predict.deliverables.factors.filter.country.itemsTitle'),
            data: [
                {
                    value: country,
                    label: country,
                    selected: true
                }
            ]
        };
    }

    /**
     * Returns concepts filter options.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {DropdownData}
     * @memberof FilterComponent
     */
    private getConceptFilterOptions(filter: FactorsFilter): DropdownData<string> {
        const dataType = (filter.compare.find(selected => selected.isSelected === true).name === 'Concepts') ? DataType.CHECKBOX : DataType.RADIO;
        const dropdownData: DropdownItem<string>[] = filter.concepts.map(item => {
            return {
                value: item.name,
                label: item.name,
                selected: item.isSelected
            };
        });
        const dropdownItems: DropdownData<string> = {
            dropdownLabel: this.translate.instant('quick.predict.deliverables.factors.filter.concepts.label'),
            dataType: dataType,
            contentTitle: this.translate.instant('quick.predict.deliverables.factors.filter.concepts.itemsTitle'),
            groupSelect: this.translate.instant('quick.predict.deliverables.factors.filter.concepts.allSelectedLabel'),
            data: dropdownData
        };

        return dropdownItems;
    }

    /**
     * Returns sub groups filter options.
     *
     * @private
     * @param {Array<FilterItem>} items
     * @returns {DropdownData}
     * @memberof FilterComponent
     */
    private getSubGroupFilterOptions(filter: FactorsFilter): DropdownData<string> {
        const dataType = (filter.compare.find(selected => selected.isSelected === true).name === 'Concepts') ? DataType.RADIO : DataType.CHECKBOX;
        const groupSelect = (dataType === DataType.RADIO ? '' : this.translate.instant('quick.predict.deliverables.factors.filter.subgroups.allSubgroups'));
        const dropdownData: DropdownItem<string>[] = filter.subgroups.map(item => {
            return {
                value: item.name,
                label: item.name,
                selected: item.isSelected
            };
        });
        const dropdownItems: DropdownData<string> = {
            dropdownLabel: this.translate.instant('quick.predict.deliverables.factors.filter.subgroups.label'),
            dataType: dataType,
            groupSelect: groupSelect,
            contentTitle: this.translate.instant('quick.predict.deliverables.factors.filter.subgroups.itemsTitle'),
            data: dropdownData
        };

        return dropdownItems;
    }

    /**
     * Emits event of the selected filter menu item.
     */
    selectedEvent(event): void {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        console.log(event);
    }

    /**
     * Sets the new filter entries when changes are made to compare drop down.
     *
     * @listens event:selectionChange
     * @param {Array<DropDownItem>}compare The list of compare options.
     */
    selectCompare(compare: Array<DropdownItem<string>>) {
        this.mixpanelService.track(this.deliverableLabel, MixpanelEvent.editFilter);
        const selectedCompare: DropdownItem<string> = compare.find((option: DropdownItem<string>) => {
            return option.selected;
        });
        const filter: FactorsFilter = JSON.parse(JSON.stringify(this.factorsService.defaultFilter));
        filter.subgroups = this.factorsService.filterSubgroups(filter.subgroups, selectedCompare.value);
        filter.show = this.factorsService.modifyShowFilter(filter, selectedCompare.value, this.factorsDeliverableView, this.isAlcoholStudy, this.isCannabisStudy);
        filter.show.factorsOptions = filter.show.factorsOptions.filter(fo => this.factorsService.getFactorsConfiguration()[fo.id] != undefined && this.factorsService.getFactorsConfiguration()[fo.id]);
        filter.show.factorsOptions = filter.show.factorsOptions.filter(options => !this.factorsService.getExcludedKmaFactors().includes(options.id.toString()));
        const newFilterData = {
            ...this.filter,
            deliverableViewType: selectedCompare.value,
            compare: this.filter.compare.map((item: FilterItem) => {
                return {
                    ...item,
                    isSelected: compare.find(option => option.value === item.id).selected
                };
            }),
            concepts: this.filter.concepts.map((concept, index) => {
                return {
                    ...concept,
                    isSelected: compare.find(selected => selected.selected === true && selected.value === 'subgroup') ? (index === 0 ? true : false) : true
                };
            }),
            subgroups: filter.subgroups.map((subgroup, index) => {
                return {
                    ...subgroup,
                    isSelected: compare.find(selected => selected.selected === true && selected.value === 'concept') ? (index === 0 ? true : false) : true
                };
            }),
            show: filter.show
        };
        this.filterService.update(newFilterData);
    }

    /**
     * Cleanup the component on removing from the UI.
     *
     * @memberof FilterComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
