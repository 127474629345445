import {Concept} from '@platform/models/concept.model';
import {ScoreCardView} from '@platform/score-cards/score-card-view';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ExportPngService} from '@platform/services/export-png.service';
import {ViewMetaInfoService} from '@platform/services/view-meta-info.service';
import {SpinnerService} from '@platform/services/spinner.service';
import {DeliverableInsightService} from '@platform/services/deliverable-insight.service';
import {UserService} from '@platform/services/user.service';
import {skipWhile, take, debounceTime} from 'rxjs/operators';
import {TGAFilter} from '@app/deliverables/target-group-analysis/models/filter.model';
import {TgaService} from '@app/deliverables/target-group-analysis/services/tga.service';
import {combineLatest, Subscription} from 'rxjs';
import {TGAMetaInfo} from '@app/deliverables/target-group-analysis/models/view-meta-info.model';
import {TGADeliverableView} from '@app/deliverables/target-group-analysis/models/tga.model';
import {DeliverableInsight} from '@platform/deliverable-insight/deliverable-insight.model';
import {ReportService} from '@platform/services/report.service';
import {DeliverableType} from '@app/deliverables/deliverable-type.enum';
import {DeliverableViewService} from '@platform/services/deliverable-view.service';
import {DeliverableView} from '@platform/models/deliverable-view.model';
import {DeliverableViewType} from "@app/deliverables/target-group-analysis/models/deliverable-view-type-enum";

@Component({
    selector: 'ns-target-group-analysis',
    templateUrl: './target-group-analysis.component.html',
    styleUrls: ['./target-group-analysis.component.scss']
})
export class TargetGroupAnalysisComponent implements OnInit, OnDestroy, ScoreCardView {

    /**
     * TGA deliverable view
     * @type {TGADeliverableView} tgaDeliverableView
     * @memberOf TargetGroupAnalysisComponent
     */
    public tgaDeliverableView: TGADeliverableView;

    /**
     * toggle selected View
     * @type {Boolean} selectedView
     * @memberOf TargetGroupAnalysisComponent
     */
    public previousAnalysis: boolean = false;
    /**
     * Subscription objects for cleanup.
     *
     * @type {Array<Subscription>}
     * @member TargetGroupAnalysisComponent
     */
    public subscriptions: Array<Subscription>;
    /**
     * Subscription objects for cleanup.
     *
     * @type {TGAFilter} filter
     * @member TargetGroupAnalysisComponent
     */
    public filter: TGAFilter;
    public isInternalUser: Boolean;
    /**
     * toggle insight btn
     * @type {Boolean} isInsightEnable
     * @memberOf TargetGroupAnalysisComponent
     */
    public isInsightEnable = false;

    public disableBtn: boolean;

    /**
     * The deliverable insight data when creating insight.
     * @type {DeliverableInsight} deliverableData
     * @memberOf TargetGroupAnalysisComponent
     */
    public deliverableData: DeliverableInsight;

    /**
     * Meta info for TGA
     *
     * @type {TGAMetaInfo}
     * @memberOf TargetGroupAnalysisComponent
     */
    public viewTGAMetaInfo: TGAMetaInfo;


    /**
     * Spinner.
     *
     * @type {Array<any>}
     * @member AttributesComponent
     */
    public displayProgressSpinner = false;

    /**
     * ScoreCard Concept object for target group analysis.
     *
     * @type {Concept}
     */
    public scoreCardConcept: Concept;

    /**
     * Feature FLAG for Automatic Headlines.
     *
     * @type {Boolean}
     */
    public isAutomatedHeadlinesEnabled: boolean;

    public deliverableViews: Array<DeliverableView>;

    constructor(
        private reportService: ReportService,
        private tgaService: TgaService,
        private exportPNGService: ExportPngService,
        private viewMetaInfoService: ViewMetaInfoService,
        private spinnerService: SpinnerService,
        private deliverableInsightService: DeliverableInsightService,
        private deliverableViewService: DeliverableViewService,
        private userService: UserService
    ) {
        this.subscriptions = [];
    }

    ngOnInit(): void {
        const user$ = this.userService.getUser();
        const report$ = this.reportService.get();
        const tga$ = this.tgaService.getTGA();
        const filter$ = this.tgaService.getTGAFilter();
        const deliverableType = DeliverableType.TARGET_GROUP_ANALYSIS.type;
        const viewMetaInfo$ = this.viewMetaInfoService.get<TGAMetaInfo>(deliverableType);
        const deliverableViews$ = this.deliverableViewService.getDeliverableViews(deliverableType);
        const userSubscription = user$.pipe(take(1))
            .subscribe((user) => {
                this.isInternalUser = user.isInternalUser;
                this.isAutomatedHeadlinesEnabled = user.featureFlags.includes('REPORTING_AUTOMATED_HEADLINES');
            });
        const subscription = combineLatest([report$, tga$, filter$, viewMetaInfo$, deliverableViews$])
            .pipe(
                debounceTime(1),
                skipWhile(([report, tga, filter, viewMetaInfo, deliverableViews]) => viewMetaInfo == null)
            ).subscribe(([report, tga, filters, viewMetaInfo, deliverableViews]) => {
                    this.deliverableViews = deliverableViews;
                    this.previousAnalysis = tga.previousAnalysis;
                    this.tgaDeliverableView = tga;
                    this.filter = filters;
                    this.viewTGAMetaInfo = viewMetaInfo;
                    this.disableBtn = this.isConceptSubgroupExists(filters);
                    if(this.isInsightEnable && this.isAutomatedHeadlinesEnabled) {
                        this.openInsightCreationForm();
                    }
                }
            );

        this.subscriptions.push(userSubscription);
        this.subscriptions.push(subscription);
    }

    /**
     * capture screen layout and export as png.
     *
     */
    exportAsPNG() {
        this.displayProgressSpinner = true;
        this.exportPNGService.exportPNG();
        this.spinnerService.getSpinnerObs().subscribe(loading => this.displayProgressSpinner = loading);
    }

    /**
     * check concept count
     * */
    isConceptSubgroupExists(filter: TGAFilter): boolean {
        const conceptCount = filter.concepts.filter(it => it.isSelected === true).length;
        const subgroupCount = filter.subgroups.filter(it => it.isSelected === true).length;
        return (conceptCount === 0 || subgroupCount === 0);
    }

    /**
     * toggle between headers and insight creation form.
     *
     */
    openInsightCreationForm() {
        this.setPreviousAnalysisInMetaInfo();
        this.isInsightEnable = true;
        const insightHTMLData = this.deliverableInsightService.getInsightHTML();
        const deliverableView = this.deliverableViews.find(it => it.viewName === DeliverableViewType.TARGETGROUP);
        this.deliverableData = {
            deliverable: {
                deliverableViewId: deliverableView.id,
                filter: this.filter,
                metaInfo: this.viewTGAMetaInfo,
                insightHTML: insightHTMLData
            }
        };
    }

    /**
     * set previous analysis in meta info
     *
     */
    setPreviousAnalysisInMetaInfo() {
        const deliverableType = DeliverableType.TARGET_GROUP_ANALYSIS.type;
        const viewInfo = {deliverableType, previousAnalysis: false};
        viewInfo.previousAnalysis = this.previousAnalysis;
        this.viewMetaInfoService.update(viewInfo, deliverableType);
    }

    /**
     * Close insight form
     */
    closeInsight() {
        this.isInsightEnable = false;
    }

    /**
     * Cleanup hook.
     *
     * @memberOf SurveyQuestionComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
