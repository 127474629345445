<div class="header-text">
    <label for="save">{{label}}</label>
    <input id="save" (keyup)="onValueChange($event)" (focus)="onFocus()" (focusin)="focusIn()"
           (focusout)="focusout()"  [(ngModel)]="value" [attr.placeholder]="placeholderText"
           type="text" [ngClass]="titleBorderClass" class="input-headers-headlines"/>
</div>
<p [ngClass]="titleTextClass" class="text-notice" *ngIf="noticeVisible">
    {{noticeText}}
</p>
<p [ngClass]="titleTextClass" class="text-count">{{value?.length ? value.length : 0}} / {{maxLength}}</p>
<br>

